import { fabric } from "fabric";
import { ElementType } from "src/app/models";
import { X_ROUTE } from "../canvas/fabric/constants/object-keys";
import { LineType } from "./line-type.enum";
import { Keys } from "./util";

type VerticalLineCoords = {
  x: number;
  y1: number;
  y2: number;
  objRoute?: number[];
};

type HorizontalLineCoords = {
  y: number;
  x1: number;
  x2: number;
  objRoute?: number[];
};

type CanvasObjectTypes = { key: string; value: any }[];

type ACoordsAppendCenter = NonNullable<fabric.Object["aCoords"]> & {
  c: fabric.Point;
};
export class AlignGuidelines {
  lineMargin = 5;
  aligningStrokeWidth = 1;
  aligningStrokeColor = 'black';
  highlightObjectStrokeColor = 'black';
  highlightObjectStrokeWidth = 1;
  lineOffset = 20;
  ignoreSelectedObjTypes: CanvasObjectTypes = [];
  ignoreCanvasObjTypes: CanvasObjectTypes = [];
  pickCanvasObjTypes: CanvasObjectTypes = [];

  canvas: fabric.Canvas;
  ctx: CanvasRenderingContext2D;
  viewportTransform: number[];
  verticalLines: VerticalLineCoords[] = [];
  horizontalLines: HorizontalLineCoords[] = [];
  activeObj: fabric.Object = new fabric.Object();
  magnetismEnabled = true;

  constructor({
    canvas,
    aligningOptions,
    ignoreSelectedObjTypes,
    ignoreCanvasObjTypes: ignoreObjTypes,
    highlightObjectOptions,
  }: {
    canvas: fabric.Canvas;
    ignoreSelectedObjTypes?: CanvasObjectTypes;
    ignoreCanvasObjTypes?: CanvasObjectTypes;
    aligningOptions?: {
      strokeWidth?: number;
      strokeColor?: string;
    };
    highlightObjectOptions?: {
      strokeColor?: string;
      strokeWidth?: number;
    }
  }) {
    this.canvas = canvas;
    this.ctx = canvas.getSelectionContext();
    this.ignoreSelectedObjTypes = ignoreSelectedObjTypes || [];
    this.ignoreCanvasObjTypes = ignoreObjTypes || [];
    if (aligningOptions) {
      this.aligningStrokeWidth = aligningOptions.strokeWidth || this.aligningStrokeWidth;
      this.aligningStrokeColor = aligningOptions.strokeColor || this.aligningStrokeColor;
    }
    if (highlightObjectOptions) {
      this.highlightObjectStrokeColor = highlightObjectOptions.strokeColor || this.highlightObjectStrokeColor;
      this.highlightObjectStrokeWidth = highlightObjectOptions.strokeWidth || this.highlightObjectStrokeWidth;
    }
  }

  init() {
    // Setup mouse event(s)
    this.canvas.on("mouse:down", (e) => this.onCanvasMouseDown(e));
    this.canvas.on("mouse:up", (e) => this.onCanvasMouseUp(e));
    this.canvas.on("mouse:wheel", (e) => this.onCanvasMouseWheel(e));

    // Setup object event(s)
    this.canvas.on("object:moving", (e) => this.onCanvasObjectMoving(e));

    // Setup render event(s)
    this.canvas.on("before:render", (e) => this.onCanvasBeforeRender(e));
    this.canvas.on("after:render", (e) => this.onCanvasAfterRender(e));
  }

  enableMagnetism() {
    this.magnetismEnabled = true;
    this.lineMargin = 5;
  }

  disableMagnetism() {
    this.magnetismEnabled = false;
    this.lineMargin = 0;
  }

  clearLines() {
    this.clearLinesMeta();
  }

  onCanvasObjectMoving(e: fabric.IEvent<MouseEvent>) {
    this.activeObj?.setCoords();
    this.clearLinesMeta();

    this.activeObj = e.transform.target;

    if (
      this.ignoreSelectedObjTypes.length &&
      this.ignoreSelectedObjTypes.some(({ key, value }) => this.activeObj[key] === value)
    ) {
      return;
    }

    const canvasObjects = this.canvas.getObjects()
      .filter((o) => {
        return (
          !this.ignoreCanvasObjTypes.length ||
          (
            o != this.activeObj &&
            !this.ignoreCanvasObjTypes.some(({ key, value }) => o[key] === value)
          )
        );
      });

    this.traversAllObjects(e.transform.target, canvasObjects);
  }

  onCanvasMouseDown(e: fabric.IEvent<MouseEvent>) {
    this.clearLinesMeta();
    this.viewportTransform = this.canvas.viewportTransform;
  }

  onCanvasMouseUp(e: fabric.IEvent<MouseEvent>) {
    this.clearLinesMeta();
    this.canvas.requestRenderAll();
  }

  onCanvasMouseWheel(e: fabric.IEvent<MouseEvent>) {
    this.clearLinesMeta();
  }

  onCanvasBeforeRender(e: fabric.IEvent<MouseEvent>) {
    this.clearGuideline();
  }

  onCanvasAfterRender(e: fabric.IEvent<MouseEvent>) {
    this.verticalLines.forEach((l) => this.drawVerticalLine(l));
    this.horizontalLines.forEach((l) => this.drawHorizontalLine(l));

    this.canvas.calcOffset();
  }

  private drawLine(x1: number, y1: number, x2: number, y2: number, lineType: LineType) {
    const ctx = this.ctx;
    const point1 = fabric.util.transformPoint(new fabric.Point(x1, y1), this.canvas.viewportTransform as any);
    const point2 = fabric.util.transformPoint(new fabric.Point(x2, y2), this.canvas.viewportTransform as any);

    // use origin canvas api to draw guideline
    ctx.save();
    ctx.lineWidth = this.aligningStrokeWidth;
    ctx.strokeStyle = this.aligningStrokeColor;
    ctx.beginPath();

    if (lineType === LineType.Dotted) {
      ctx.setLineDash([3, 3]);
    }

    ctx.moveTo(point1.x, point1.y);
    ctx.lineTo(point2.x, point2.y);

    ctx.stroke();
    ctx.restore();
  }

  private drawVerticalLine(coords: VerticalLineCoords) {
    const movingCoords = this.getObjDraggingObjCoords(this.activeObj);
    const inRangeObject = this.canvas.getObjects().find(obj => obj[X_ROUTE]?.toString() == coords.objRoute?.toString());
    if (!inRangeObject || (this.magnetismEnabled && !Keys(movingCoords).some((key) => Math.abs(movingCoords[key].x - coords.x) < 0.0001))) return;
    // @ts-expect-error
    if (inRangeObject.elementType == ElementType.page) {
      this.drawLine(coords.x, Math.min(coords.y1, coords.y2), coords.x, Math.max(coords.y1, coords.y2), LineType.Solid);
    }
    else {
      this.drawLine(coords.x, Math.min(coords.y1, coords.y2), coords.x, Math.max(coords.y1, coords.y2), LineType.Dotted);
    }
  }

  private drawHorizontalLine(coords: HorizontalLineCoords) {
    const movingCoords = this.getObjDraggingObjCoords(this.activeObj);
    const inRangeObject = this.canvas.getObjects().find(obj => obj[X_ROUTE]?.toString() == coords.objRoute?.toString());
    if (!inRangeObject || (this.magnetismEnabled && !Keys(movingCoords).some((key) => Math.abs(movingCoords[key].y - coords.y) < 0.0001))) return;
    // @ts-expect-error
    if (inRangeObject.elementType == ElementType.page) {
      this.drawLine(Math.min(coords.x1, coords.x2), coords.y, Math.max(coords.x1, coords.x2), coords.y, LineType.Solid);
    }
    else {
      this.drawLine(Math.min(coords.x1, coords.x2), coords.y, Math.max(coords.x1, coords.x2), coords.y, LineType.Dotted);
    }
  }

  private isInRange(value1: number, value2: number) {
    return Math.abs(Math.round(value1) - Math.round(value2)) <= this.lineMargin / this.canvas.getZoom();
  }

  private clearLinesMeta() {
    this.verticalLines.length = this.horizontalLines.length = 0;
  }

  private getObjDraggingObjCoords(activeObject: fabric.Object) {
    const aCoords = activeObject.aCoords as NonNullable<fabric.Object["aCoords"]>;
    const centerPoint = new fabric.Point((aCoords.tl.x + aCoords.br.x) / 2, (aCoords.tl.y + aCoords.br.y) / 2);

    const offsetX = centerPoint.x - activeObject.getCenterPoint().x;
    const offsetY = centerPoint.y - activeObject.getCenterPoint().y;

    return Keys(aCoords).reduce(
      (acc, key) => {
        return {
          ...acc,
          [key]: {
            x: aCoords[key].x - offsetX,
            y: aCoords[key].y - offsetY,
          },
        };
      },
      {
        c: activeObject.getCenterPoint(),
      } as ACoordsAppendCenter
    );
  }

  private omitCoords(objCoords: ACoordsAppendCenter, type: "vertical" | "horizontal") {
    let newCoords;
    type PointArr = [keyof ACoordsAppendCenter, fabric.Point];
    if (type === "vertical") {
      let l: PointArr = ["tl", objCoords.tl];
      let r: PointArr = ["tl", objCoords.tl];
      Keys(objCoords).forEach((key) => {
        if (objCoords[key].x < l[1].x) {
          l = [key, objCoords[key]];
        }
        if (objCoords[key].x > r[1].x) {
          r = [key, objCoords[key]];
        }
      });
      newCoords = {
        [l[0]]: l[1],
        [r[0]]: r[1],
        c: objCoords.c,
      } as ACoordsAppendCenter;
    } else {
      let t: PointArr = ["tl", objCoords.tl];
      let b: PointArr = ["tl", objCoords.tl];
      Keys(objCoords).forEach((key) => {
        if (objCoords[key].y < t[1].y) {
          t = [key, objCoords[key]];
        }
        if (objCoords[key].y > b[1].y) {
          b = [key, objCoords[key]];
        }
      });
      newCoords = {
        [t[0]]: t[1],
        [b[0]]: b[1],
        c: objCoords.c,
      } as ACoordsAppendCenter;
    }
    return newCoords;
  }

  private getObjMaxWidthHeightByCoords(coords: ACoordsAppendCenter) {
    const objHeight = Math.max(Math.abs(coords.c.y - coords["tl"].y), Math.abs(coords.c.y - coords["tr"].y)) * 2;
    const objWidth = Math.max(Math.abs(coords.c.x - coords["tl"].x), Math.abs(coords.c.x - coords["tr"].x)) * 2;
    return { objHeight, objWidth };
  }

  /**
   * fabric.Object.getCenterPoint will return the center point of the object calc by mouse moving & dragging distance.
   * calcCenterPointByACoords will return real center point of the object position.
   */
  private calcCenterPointByACoords(coords: NonNullable<fabric.Object["aCoords"]>): fabric.Point {
    return new fabric.Point((coords.tl.x + coords.br.x) / 2, (coords.tl.y + coords.br.y) / 2);
  }

  private traversAllObjects(activeObject: fabric.Object, canvasObjects: fabric.Object[]) {
    const objCoordsByMovingDistance = this.getObjDraggingObjCoords(activeObject);
    const lineOffset = this.lineOffset;
    const snapXPoints: number[] = [];
    const snapYPoints: number[] = [];

    for (let i = canvasObjects.length; i--;) {
      const objCoords = {
        ...canvasObjects[i].aCoords,
        c: canvasObjects[i].getCenterPoint(),
      } as ACoordsAppendCenter;
      const { objHeight, objWidth } = this.getObjMaxWidthHeightByCoords(objCoords);
      Keys(objCoordsByMovingDistance).forEach((activeObjPoint) => {
        const newCoords = canvasObjects[i].angle !== 0 ? this.omitCoords(objCoords, "horizontal") : objCoords;

        function calcHorizontalLineCoords(objPoint: keyof ACoordsAppendCenter, activeObjCoords: ACoordsAppendCenter) {
          let x1: number, x2: number;
          const initialObjPoint = objPoint;
          const initialActiveObjPoint = activeObjPoint;
          if (objPoint === "c") {
            if (objCoords[objPoint].x < activeObjCoords[activeObjPoint].x) {
              activeObjPoint = activeObjPoint === 'tl' ? 'tr' : 'br';
            }
            else {
              activeObjPoint = activeObjPoint === 'tr' ? 'tl' : 'bl';
            }
            x1 = Math.min(objCoords.c.x - objWidth / 2, activeObjCoords[activeObjPoint].x) - lineOffset;
            x2 = Math.max(objCoords.c.x + objWidth / 2, activeObjCoords[activeObjPoint].x) + lineOffset;
          }
          else {
            if (objCoords[objPoint].x < activeObjCoords[activeObjPoint].x) {
              objPoint = objPoint === 'tr' ? 'tl' : 'bl';
              activeObjPoint = activeObjPoint === 'tl' ? 'tr' : 'br';
            }
            else {
              objPoint = objPoint === 'tl' ? 'tr' : 'br';
              activeObjPoint = activeObjPoint === 'tr' ? 'tl' : 'bl';
            }
            x1 = Math.min(objCoords[objPoint].x, activeObjCoords[activeObjPoint].x) - lineOffset;
            x2 = Math.max(objCoords[objPoint].x, activeObjCoords[activeObjPoint].x) + lineOffset;
          }
          objPoint = initialObjPoint;
          activeObjPoint = initialActiveObjPoint;
          return { x1, x2 };
        }

        Keys(newCoords).forEach((objPoint) => {
          if (this.isInRange(objCoordsByMovingDistance[activeObjPoint].y, objCoords[objPoint].y)) {
            const y = objCoords[objPoint].y;
            let { x1, x2 } = calcHorizontalLineCoords(objPoint, objCoordsByMovingDistance);

            const offset = objCoordsByMovingDistance[activeObjPoint].y - y;
            snapYPoints.push(objCoordsByMovingDistance.c.y - offset);

            if (activeObject.aCoords) {
              let { x1, x2 } = calcHorizontalLineCoords(objPoint, {
                ...activeObject.aCoords,
                c: this.calcCenterPointByACoords(activeObject.aCoords),
              } as ACoordsAppendCenter);
              this.horizontalLines.push({ y, x1, x2 });
            } else {
              this.horizontalLines.push({ y, x1, x2 });
            }
            this.horizontalLines[this.horizontalLines.length - 1].objRoute = canvasObjects[i][X_ROUTE];
          }
        });
      });

      Keys(objCoordsByMovingDistance).forEach((activeObjPoint) => {
        const newCoords = canvasObjects[i].angle !== 0 ? this.omitCoords(objCoords, "vertical") : objCoords;

        function calcVerticalLineCoords(objPoint: keyof ACoordsAppendCenter, activeObjCoords: ACoordsAppendCenter) {
          let y1: number, y2: number;
          const initialObjPoint = objPoint;
          const initialActiveObjPoint = activeObjPoint;
          if (objPoint === "c") {
            if (objCoords[objPoint].y < activeObjCoords[activeObjPoint].y) {
              activeObjPoint = activeObjPoint === 'tl' ? 'bl' : 'br';
            }
            else {
              activeObjPoint = activeObjPoint === 'bl' ? 'tl' : 'tr';
            }
            y1 = Math.min(newCoords.c.y - objHeight / 2, activeObjCoords[activeObjPoint].y) - lineOffset;
            y2 = Math.max(newCoords.c.y + objHeight / 2, activeObjCoords[activeObjPoint].y) + lineOffset;
          } else {
            if (objCoords[objPoint].y < activeObjCoords[activeObjPoint].y) {
              activeObjPoint = activeObjPoint === 'tl' ? 'bl' : 'br';
              objPoint = objPoint === 'bl' ? 'tl' : 'tr';
            }
            else {
              activeObjPoint = activeObjPoint === 'bl' ? 'tl' : 'tr';
              objPoint = objPoint === 'tl' ? 'bl' : 'br';
            }
            y1 = Math.min(objCoords[objPoint].y, activeObjCoords[activeObjPoint].y) - lineOffset;
            y2 = Math.max(objCoords[objPoint].y, activeObjCoords[activeObjPoint].y) + lineOffset;
          }
          objPoint = initialObjPoint;
          activeObjPoint = initialActiveObjPoint;
          return { y1, y2 };
        }

        Keys(newCoords).forEach((objPoint) => {
          if (this.isInRange(objCoordsByMovingDistance[activeObjPoint].x, objCoords[objPoint].x)) {
            const x = objCoords[objPoint].x;
            let { y1, y2 } = calcVerticalLineCoords(objPoint, objCoordsByMovingDistance);

            const offset = objCoordsByMovingDistance[activeObjPoint].x - x;
            snapXPoints.push(objCoordsByMovingDistance.c.x - offset);

            if (activeObject.aCoords) {
              let { y1, y2 } = calcVerticalLineCoords(objPoint, {
                ...activeObject.aCoords,
                c: this.calcCenterPointByACoords(activeObject.aCoords),
              } as ACoordsAppendCenter);
              this.verticalLines.push({ x, y1, y2 });
            } else {
              this.verticalLines.push({ x, y1, y2 });
            }
            this.verticalLines[this.verticalLines.length - 1].objRoute = canvasObjects[i][X_ROUTE];
          }
        });
      });

      if (this.magnetismEnabled) {
        this.snap({
          activeObject,
          draggingObjCoords: objCoordsByMovingDistance,
          snapXPoints,
          snapYPoints,
        });
      }
    }
  }

  private snap({
    activeObject,
    snapXPoints,
    draggingObjCoords,
    snapYPoints,
  }: {
    activeObject: fabric.Object;
    snapXPoints: number[];
    draggingObjCoords: ACoordsAppendCenter;
    snapYPoints: number[];
  }) {
    const sortPoints = (list: number[], originPoint: number) => {
      if (!list.length) return originPoint;
      return list
        .map((val) => ({
          abs: Math.abs(originPoint - val),
          val,
        }))
        .sort((a, b) => a.abs - b.abs)[0].val;
    };
    activeObject.setPositionByOrigin(
      // auto snap nearest object, record all the snap points, and then find the nearest one
      new fabric.Point(sortPoints(snapXPoints, draggingObjCoords.c.x), sortPoints(snapYPoints, draggingObjCoords.c.y)),
      "center",
      "center"
    );
  }

  private clearGuideline() {
    this.canvas.clearContext(this.ctx);
  }
}
