import userFromImport from 'src/test-mock-data/import-user.json';
import { FunctionPermissions, SetElementPermissions } from '../models';
import { User } from '../models/user';

export class FakeUser extends User {
  password = 'test';
}

const userFunctionPermissions = new FunctionPermissions();
const userSetElementPermissions = new SetElementPermissions();

const msFunctionPermissions: FunctionPermissions = {
  importExportDesign: false,
  saveDesignInCollectionOtherUser: false,
  updateExistingDesign: false,
  submitDesignForReview: false,
  formatPages: false,
  hideSaveContinueDialog: false,
  canAddBox: false,
  showFoilSafetyMarginWarning: false,
  addImageAsPhotoFrame: true,
  hasDimensionsInputField: false,
  autoSave: false,
  showGuideLines: false,
  toggleSpreads: false,
  canAddTag: false,
  canAddTextInline: false,
  canAddLabels: false,
  canChangeBorderTrim: false,
  canChangeBackgroundColor: true,
  canChangeMaterial: false,
  canSave: false,
  objectDetectionUpload: false,
  canEditStyle: false,
  allowCrossSessionElementCopying: false,
  enableFoilableByDefault: false,
  spotUvPermissionForJpgByDefault: false,
  useChat: false,
  resizeText: false,
  showUpdateDialog: true,
  canSearchImageLibrary: false,
  canCreatePdf: false,
  canAddSpotUv: false,
  enableImageUpload: true,
  setCharacterLimit: true
};

const designerFunctionPermissions: FunctionPermissions = {
  importExportDesign: true,
  saveDesignInCollectionOtherUser: false,
  updateExistingDesign: true,
  submitDesignForReview: true,
  formatPages: false,
  hideSaveContinueDialog: false,
  canAddBox: false,
  showFoilSafetyMarginWarning: false,
  addImageAsPhotoFrame: false,
  hasDimensionsInputField: true,
  autoSave: true,
  showGuideLines: true,
  toggleSpreads: true,
  canAddTag: true,
  canAddTextInline: true,
  canAddLabels: true,
  canChangeBorderTrim: true,
  canChangeBackgroundColor: true,
  canChangeMaterial: true,
  canSave: true,
  objectDetectionUpload: false,
  canEditStyle: false,
  allowCrossSessionElementCopying: true,
  enableFoilableByDefault: true,
  spotUvPermissionForJpgByDefault: false,
  useChat: false,
  resizeText: false,
  showUpdateDialog: true,
  canSearchImageLibrary: true,
  canCreatePdf: true,
  canAddSpotUv: true,
  enableImageUpload: true,
  setCharacterLimit: true
};

const designerSetElementPermissions: SetElementPermissions = {
  isLocked: true,
  isPrintable: true,
  isRecolorable: true,
  isResizable: false,
  isDuplicatable: true,
  isRotatable: true,
  isRemovable: true,
  isMovable: false,
  isCroppable: true,
  isReplaceable: true,
  isInstantReplaceable: true,
  hasInstantReplaceablePlaceholder: true,
  isUntargetable: true,
  isFlippable: true,
  isTopLayer: true,
  isFoilable: true,
  addChildElements: true,
  adjustTransparency: true,
  hasCoating: true,
  isSpotUvable: true,
  isCutThrough: true,
  cutThroughInverted: true,
  isKissCut: true,
  isCrease: true,
  isPerforation: true,
  isFillable: true,
  isOrderable: true,
  adjustTrim: true,
  changeBackgroundColor: true,
  changeMaterial: true,
  isHidden: true,
  useRichText: true,
  shrinkToFit: true,
  excludeFromPreviews: true,
  isVisibleOutsideCropArea: true,
  isExternallyEditable: true,
  fontChangeable: true,
  fontResizable: true,
  textEditable: true
};

const adminFunctionPermissions: FunctionPermissions = {
  importExportDesign: true,
  saveDesignInCollectionOtherUser: true,
  updateExistingDesign: true,
  submitDesignForReview: true,
  formatPages: true,
  hideSaveContinueDialog: true,
  canAddBox: true,
  showFoilSafetyMarginWarning: false,
  addImageAsPhotoFrame: true,
  hasDimensionsInputField: true,
  autoSave: false,
  showGuideLines: true,
  toggleSpreads: true,
  canAddTag: true,
  canAddTextInline: true,
  canAddLabels: true,
  canChangeBorderTrim: true,
  canChangeBackgroundColor: true,
  canChangeMaterial: true,
  canSave: true,
  objectDetectionUpload: true,
  canEditStyle: true,
  allowCrossSessionElementCopying: true,
  enableFoilableByDefault: true,
  spotUvPermissionForJpgByDefault: false,
  useChat: false,
  resizeText: true,
  showUpdateDialog: true,
  canSearchImageLibrary: true,
  canCreatePdf: true,
  canAddSpotUv: true,
  enableImageUpload: true,
  setCharacterLimit: true
};

const adminSetElementPermissions: SetElementPermissions = {
  isLocked: true,
  isPrintable: true,
  isRecolorable: true,
  isResizable: true,
  isDuplicatable: true,
  isRotatable: true,
  isRemovable: true,
  isMovable: true,
  isCroppable: true,
  isReplaceable: true,
  isInstantReplaceable: true,
  hasInstantReplaceablePlaceholder: true,
  isUntargetable: true,
  isFlippable: true,
  isTopLayer: true,
  isFoilable: true,
  addChildElements: true,
  adjustTransparency: true,
  hasCoating: true,
  isSpotUvable: true,
  isCutThrough: true,
  cutThroughInverted: true,
  isKissCut: true,
  isCrease: true,
  isPerforation: true,
  isFillable: true,
  isOrderable: true,
  adjustTrim: true,
  changeBackgroundColor: true,
  changeMaterial: true,
  isHidden: true,
  useRichText: true,
  shrinkToFit: true,
  excludeFromPreviews: true,
  isVisibleOutsideCropArea: true,
  isExternallyEditable: true,
  fontChangeable: true,
  fontResizable: true,
  textEditable: true
};

export const notLoggedInUser = new User('', '', '', 0, new FunctionPermissions(), new SetElementPermissions(), false);
notLoggedInUser.functionPermissions.canAddTextInline = true;
notLoggedInUser.functionPermissions.canChangeBorderTrim = true;
notLoggedInUser.functionPermissions.canChangeBackgroundColor = true;

export const fakeUser = new FakeUser(
  'piet',
  'mooze',
  'piet@mooze.nl',
  55,
  userFunctionPermissions,
  userSetElementPermissions
);
fakeUser.functionPermissions.canChangeBorderTrim = true;
fakeUser.functionPermissions.canChangeBackgroundColor = true;

const fakeMSUser = new FakeUser(
  'marketing',
  'marketingsuite',
  'mark@ms.nl',
  0,
  msFunctionPermissions,
  userSetElementPermissions
);

const fakeDesigner = new FakeUser(
  'designer',
  'mooze',
  'designer@mooze.nl',
  0,
  designerFunctionPermissions,
  designerSetElementPermissions
);

const fakeAdmin = new FakeUser(
  'admin',
  'mooze',
  'admin@mooze.nl',
  1,
  adminFunctionPermissions,
  adminSetElementPermissions
);

const importUser = new FakeUser(
  userFromImport['Username'],
  userFromImport['Shop'],
  userFromImport['Email'],
  userFromImport['BasketItems'],
  { ...new FunctionPermissions(), ...userFromImport['FunctionPermissions'] },
  { ...new SetElementPermissions(), ...userFromImport['SetElementPermissions'] }
);

export const fakeUsers: FakeUser[] = [fakeUser, fakeMSUser, fakeAdmin, fakeDesigner, importUser];
