import { cloneDeep, isEqual } from 'lodash-es';
import { DEFAULT_INLINE_TEXT_PADDING, SVG_VIEWBOX_SCALE } from '../../../../react-text-editor/models/text-editor.model';
import { CanvasActionTypes, CanvasActions } from '../../actions';
import {
  BASELINE_PAGEWIDTH,
  BackgroundElement,
  BackgroundImageElement,
  BoxElement,
  CanvasElement,
  DefaultFont,
  DefaultFontColor,
  DefaultFontSize,
  Design,
  ElementPermission,
  ElementType,
  FoilTypes,
  ImageElement,
  InlineTextElement,
  LayerType,
  LayerableElement,
  Material,
  PageElement,
  PhotoFrameElement,
  SpecialColorElement,
  TextElement,
  View,
  getLayerSiblings,
  toggleWhenHasLayerType,
  toggleWhenIsLocked
} from '../../models';
import { DesignSet } from '../../models/design-set';
import { cloneElement, coerceNumber, elementIsJpgImage, fileExtensionIsSvg, getFlatChildren, roundToHalf } from '../../utils/element.utils';
import { fillPage, getFillHorizontal, getFillVertical, setFillDimensions } from '../../utils/fill.utils';
import { getSpreads } from '../../utils/spreads.utils';

export function canvasReducer(state: DesignSet, action: CanvasActions.All): DesignSet {
  // if it's not a canvas action return state, no clone of state
  if (!Object.values(CanvasActionTypes).find(a => a === action.type)) {
    return state;
  }

  const newState = cloneDeep(state);

  switch (action.type) {
    case CanvasActionTypes.INIT: {
      return action.designSet;
    }

    case CanvasActionTypes.INIT_DESIGN: {
      newState.init = true;
      const rectangle = action.rectangle;
      newState.x = rectangle.x;
      newState.y = rectangle.y;
      newState.width = rectangle.width;
      newState.height = rectangle.height;
      newState.pixelsPerMm = rectangle.pixelsPerMm;

      newState.designs.map(d => d.pages.map(p => fillPage(newState, p)));

      // TODO: directly add to set
      newState.designs.forEach(d => (d.designFonts = d.getDesignFonts()));

      newState.visiblePage.fonts = newState.visiblePage.getInlineFonts();
      return newState;
    }

    case CanvasActionTypes.FILL_PAGE: {
      const rectangle = action.rectangle;
      newState.x = rectangle.x;
      newState.y = rectangle.y;

      // height and width should never be zero
      newState.width = Math.max(rectangle.width, 1);
      newState.height = Math.max(rectangle.height, 1);
      newState.pixelsPerMm = rectangle.pixelsPerMm;

      newState.designs.forEach(d => d.pages.forEach(p => fillPage(newState, p)));

      return newState;
    }

    case CanvasActionTypes.SET_USER_TITLE_AND_ID: {
      newState.userCollectionId = action.user_design_id;
      newState.userCollectionTitle = action.user_design_title;
      return newState;
    }

    case CanvasActionTypes.SET_TITLE_DESCRIPTION: {
      newState.title = action.title;
      newState.description = action.description;
      newState.id = action.id;
      newState.isProposedDesign = action.isProposed;
      return newState;
    }

    case CanvasActionTypes.SELECT: {
      const newSelectedElement = newState.getElement(action.route);
      const previousSelectedElement = newState.selectedElement;

      if (
        previousSelectedElement &&
        !previousSelectedElement.isBackgroundElement() &&
        !previousSelectedElement.isBackgroundImage() &&
        (newSelectedElement.isBackgroundElement() || newSelectedElement.isBackgroundImage())
      ) {
        newState.activeDesign.cleanUpAndDeselectElements();
      } else {
        newState.select(action.route);
      }
      return newState;
    }

    case CanvasActionTypes.TOGGLE_CROPPING_MODE: {
      const photoFrame = newState.getElement(action.route);
      newState.select(photoFrame.inCroppingMode ? photoFrame.route : photoFrame.firstChild.route);
      return newState;
    }

    case CanvasActionTypes.DE_ACTIVATE_OVERLAY: {
      const selectedElement = newState.getElement(action.route);
      selectedElement.active = false;
      selectedElement.activatedClientX = 0;
      selectedElement.activatedClientY = 0;
      return newState;
    }

    case CanvasActionTypes.ACTIVATE_OVERLAY: {
      const selectedElement = newState.getElement(action.route);
      selectedElement.active = true;
      selectedElement.activatedClientX = action.x;
      selectedElement.activatedClientY = action.y;
      return newState;
    }

    case CanvasActionTypes.SET_INLINE_TEXT_EDIT_MODE: {
      const selectedElement = newState.getElement(action.route) as InlineTextElement;
      selectedElement.editFullRange = action.editFullRange;
      return newState;
    }

    case CanvasActionTypes.TRANSLATE: {
      newState.getElement(action.route).translate(action.x, action.y, action.width, action.height, action.precise);
      return newState;
    }

    case CanvasActionTypes.RESIZE: {
      const selectedElement = newState.getElement(action.route);

      const newScreenDimensions = getNewScreenDimensions(action, selectedElement);

      const newWidth = newScreenDimensions.newScreenWidth;
      const isTextElement = selectedElement.isText() || selectedElement.isInlineText();
      const newScreenHeight = isTextElement ? newScreenDimensions.newScreenHeight : newWidth * selectedElement.ratio;

      if (isTextElement) {
        (selectedElement as InlineTextElement).resized = true;
      }

      selectedElement.resize(
        newScreenDimensions.newScreenX,
        newScreenDimensions.newScreenY,
        newScreenDimensions.newScreenWidth,
        newScreenHeight,
        action.scaleToRatio
      );

      return newState;
    }

    case CanvasActionTypes.CROP: {
      const selectedElement = newState.getElement(action.route);

      const newScreenDimensions = getNewScreenDimensions(action, selectedElement);

      selectedElement.crop(
        newScreenDimensions.newScreenX,
        newScreenDimensions.newScreenY,
        newScreenDimensions.newScreenWidth,
        newScreenDimensions.newScreenHeight
      );

      // prevent that photoframe gets bigger than its child because of rounding of x and y
      if (selectedElement.type === ElementType.photoFrame) {
        if (selectedElement.firstChild.x > 0) {
          selectedElement.width = selectedElement.width - selectedElement.firstChild.x;
          selectedElement.firstChild.x = 0;
        } else if (selectedElement.firstChild.y > 0) {
          selectedElement.height = selectedElement.height - selectedElement.firstChild.y;
          selectedElement.firstChild.y = 0;
        }
      }

      return newState;
    }

    case CanvasActionTypes.TOGGLE_PAGE: {
      newState.togglePage(action.route);
      fillPage(newState, newState.visiblePage);

      return newState;
    }

    case CanvasActionTypes.ROTATE: {
      const selectedElement = newState.select(action.route);
      selectedElement.resize(action.x, action.y, action.width, action.height);
      selectedElement.rotation = coerceNumber(action.rotation, 0);
      return newState;
    }

    case CanvasActionTypes.ROTATE_PAGE: {
      const visiblePage = newState.getElement(action.route);
      visiblePage.rotation = action.rotation;
      return newState;
    }

    case CanvasActionTypes.FLIP_HORIZONTAL: {
      const selectedElement = newState.getElement(action.route);
      selectedElement.flipHorizontal = action.flip;
      if (selectedElement.isPhotoFrameChild) {
        selectedElement.x = selectedElement.parent.width - (selectedElement.x + selectedElement.width);
      }
      return newState;
    }

    case CanvasActionTypes.FLIP_VERTICAL: {
      const selectedElement = newState.getElement(action.route);
      selectedElement.flipVertical = action.flip;
      if (selectedElement.isPhotoFrameChild) {
        selectedElement.y = selectedElement.parent.height - (selectedElement.y + selectedElement.height);
      }
      return newState;
    }

    case CanvasActionTypes.TOGGLE_ELEMENT_PRESET: {
      const selectedElement = newState.getElement(action.route);
      selectedElement.permissions[action.preset] = !selectedElement.permissions[action.preset];

      if (action.preset === ElementPermission.isFoilable) {
        selectedElement.isFoilablePermissionWasChanged = true;
      }

      if (
        action.preset === ElementPermission.isSpotUvable &&
        selectedElement.isPhotoFrame() &&
        selectedElement.firstChild.isJpg
      ) {
        (selectedElement as PhotoFrameElement).isSpotUvForJpgImagePermissionWasChanged = true;
      }

      return newState;
    }

    case CanvasActionTypes.TOGGLE_ELEMENT_PERMISSION: {
      if (action.route) {
        const selectedElement = newState.getElement(action.route);

        selectedElement.permissions[action.permission] = !selectedElement.permissions[action.permission];
        const permissionToggledOn = selectedElement.permissions[action.permission];

        if (permissionToggledOn && toggleWhenIsLocked.find(permission => permission === action.permission)) {
          selectedElement.permissions.isLocked = false;
        }

        if (permissionToggledOn && toggleWhenHasLayerType.find(permission => permission === action.permission)) {
          if (selectedElement.isLayerableElement()) {
            selectedElement.layerType = LayerType.standardRgb;
          }
          selectedElement.permissions.isVisibleOutsideCropArea = false;
        }

        if (action.permission === ElementPermission.isInstantReplaceable) {
          // if instant replaceable is toggled, toggle placeholder as well
          selectedElement.permissions.hasInstantReplaceablePlaceholder =
            selectedElement.permissions.isInstantReplaceable;
        }

        if (permissionToggledOn && action.permission === ElementPermission.isHidden) {
          // to prevent all pages are hidden, otherwise the editor will break
          selectedElement.permissions.isHidden = !newState.activeDesign.pages.every(page => page.permissions.isHidden);
        }

        if (permissionToggledOn && action.permission === ElementPermission.isInstantReplaceable) {
          // always set replaceable to true if instant replaceable is toggled on
          selectedElement.permissions.isReplaceable = true;
        }

        if (permissionToggledOn && action.permission === ElementPermission.hasInstantReplaceablePlaceholder) {
          // always set instantreplaceable to true if placeholder is toggled on
          selectedElement.permissions.isInstantReplaceable = true;
        }

        if (!permissionToggledOn && action.permission === ElementPermission.isReplaceable) {
          // always set instantreplaceable to false if replaceable is toggled off
          selectedElement.permissions.isInstantReplaceable = false;
          selectedElement.permissions.hasInstantReplaceablePlaceholder = false;
        }

        if (action.permission === ElementPermission.isResizable) {
          selectedElement.permissions.isFillable = selectedElement.permissions.isResizable;
        }

        if (permissionToggledOn && action.permission === ElementPermission.hasCoating) {
          selectedElement.permissions.adjustTransparency = false;
        }

        if (
          action.permission === ElementPermission.isVisibleOutsideCropArea &&
          selectedElement.permissions.isVisibleOutsideCropArea
        ) {
          selectedElement.permissions.isPrintable = false;
          selectedElement.permissions.isTopLayer = true;
        }
      } else {
        if (action.permission === ElementPermission.adjustTrim) {
          newState.activeDesign.permissions.adjustTrim = !newState.activeDesign.permissions.adjustTrim;
          newState.activeDesign.adjustTrimPermissionWasChanged = true;
        }

        if (action.permission === ElementPermission.changeBackgroundColor) {
          newState.activeDesign.permissions.changeBackgroundColor = !newState.activeDesign.permissions.changeBackgroundColor;
          newState.activeDesign.changeBackgroundColorPermissionWasChanged = true;
        }

        if (action.permission === ElementPermission.useRichText) {
          newState.activeDesign.permissions.useRichText = !newState.activeDesign.permissions.useRichText;
        }
      }

      newState.visiblePage.sortChildren();

      return newState;
    }

    case CanvasActionTypes.MOVE_PAGE: {
      newState.visiblePage.screenX = newState.visiblePage.screenX + action.deltaX;
      newState.visiblePage.screenY = newState.visiblePage.screenY + action.deltaY;
      return newState;
    }

    case CanvasActionTypes.REMOVE_ELEMENT: {
      newState.removeElement(action.route);
      return newState;
    }

    case CanvasActionTypes.ALIGN_TEXT: {
      const selectedElement = newState.getTextElement(action.route);
      selectedElement.ha = action.ha;
      selectedElement.isJustify = action.is_justify;
      return newState;
    }

    case CanvasActionTypes.CHANGE_TEXT: {
      const selectedElement = newState.getTextElement(action.route);
      selectedElement.textNotEdited = false;
      selectedElement.text = action.text;
      return newState;
    }

    case CanvasActionTypes.CHANGE_TEXT_INLINE:
    case CanvasActionTypes.RESIZE_TEXT_INLINE: {
      const selectedElement = newState.getElement(action.route) as InlineTextElement;

      const oldTextValue = selectedElement.textValue;

      selectedElement.init = false;
      if (action.type === CanvasActionTypes.RESIZE_TEXT_INLINE) {
        selectedElement.resized = false;
      }
      selectedElement.text = action.text;
      // coerce number here to prevent problems with undo, because height and width are recalculated when texteditor is initialized
      selectedElement.height = coerceNumber(action.height);
      selectedElement.width = coerceNumber(action.width);
      selectedElement.x = action.x;
      selectedElement.y = action.y;
      selectedElement.selection = action.selection;
      newState.activeDesign.designFonts = newState.activeDesign.getDesignFonts();

      if (!isEqual(oldTextValue, selectedElement.textValue)) {
        selectedElement.textNotEdited = false;
      }

      return newState;
    }

    case CanvasActionTypes.CHANGE_FONT: {
      newState.getTextElement(action.route).font = action.font;
      newState.activeDesign.designFonts = newState.activeDesign.getDesignFonts();
      return newState;
    }

    case CanvasActionTypes.CHANGE_COLOR: {
      const selectedElement = newState.getElement(action.route) as ImageElement | InlineTextElement | TextElement;
      selectedElement.color = action.color.colorValue;
      selectedElement.spotUv = action.color.spotUv;
      setFoilProperties(selectedElement, action.color.foil);

      newState.visiblePage.sortChildren();

      return newState;
    }

    case CanvasActionTypes.CHANGE_FONTSIZE: {
      newState.getTextElement(action.route).fontSize = action.fontsize;
      return newState;
    }

    case CanvasActionTypes.HIGHLIGHT_TEXTAREA: {
      newState.getTextElement(action.route).highlightTextarea = action.highlightTextarea;
      return newState;
    }

    case CanvasActionTypes.ENLARGE_TEXTAREA: {
      newState.getTextElement(action.route).highlightTextarea = true;
      newState.getTextElement(action.route).enlargeTextarea = action.enlargeTextarea;
      return newState;
    }

    case CanvasActionTypes.MOVE_STEP_FORWARD: {
      // if +1 swap order with element with 1 index higher if exists
      const selectedElement = newState.getElement(action.route);
      const siblings = getLayerSiblings(selectedElement);
      const selectedElementIndex = siblings.findIndex(element => element.id === selectedElement.id);
      const nextElement = siblings[selectedElementIndex + 1];
      selectedElement.order = nextElement ? nextElement.order + 0.5 : selectedElement.order;
      newState.visiblePage.sortChildren();
      newState.visiblePage.children.forEach((element, index) => (element.order = index));
      return newState;
    }

    case CanvasActionTypes.MOVE_STEP_BACKWARD: {
      // if -1 swap order with element with 1 index lower if exists
      const selectedElement = newState.getElement(action.route);
      const siblings = getLayerSiblings(selectedElement);
      const selectedElementIndex = siblings.findIndex(element => element.id === selectedElement.id);
      const nextElement = siblings[selectedElementIndex - 1];
      selectedElement.order = nextElement ? nextElement.order - 0.5 : selectedElement.order;
      newState.visiblePage.sortChildren();
      newState.visiblePage.children.forEach((element, index) => (element.order = index));
      return newState;
    }

    case CanvasActionTypes.MOVE_TO_FRONT: {
      // if to top order 1 higher then highest
      const selectedElement = newState.getElement(action.route);
      const siblings = getLayerSiblings(selectedElement);
      selectedElement.order = Math.max(...siblings.map(el => el.order)) + 1;
      newState.visiblePage.sortChildren();

      // assign new order, based on rearranged order
      newState.visiblePage.children.forEach((element, index) => (element.order = index));
      return newState;
    }

    case CanvasActionTypes.MOVE_TO_BACK: {
      // if to bottom order 1 lower then lowest
      const selectedElement = newState.getElement(action.route);
      const siblings = getLayerSiblings(selectedElement);
      selectedElement.order = Math.min(...siblings.map(el => el.order)) - 1;
      newState.visiblePage.sortChildren();
      newState.visiblePage.children.forEach((element, index) => (element.order = index));
      return newState;
    }

    case CanvasActionTypes.CHANGE_BACKGROUND_COLOR: {
      const background = newState.visiblePage.background;
      const backgroundElements = newState.visiblePage.children.filter(el => el.isBackgroundElement());

      if (!background || background.isSpreadBackgroundImage()) {
        backgroundElements.map((element: BackgroundElement) => {
          element.color = action.color;
        });
      } else if (background.isImage()) {
        background.parent.color = action.color;
      } else if (background.isBackgroundElement()) {
        background.color = action.color;
      }
      return newState;
    }

    case CanvasActionTypes.CHANGE_BACKGROUND_IMAGE: {
      const pageElement = newState.visiblePage;
      const background = pageElement.background;

      if (!background.isBackgroundImage()) {
        return newState;
      }

      const newBackgroundImage =
        background.isSpreadBackgroundImage() && pageElement.hasMultipleBackgroundElements
          ? new BackgroundImageElement(-1)
          : new ImageElement(-1);

      newBackgroundImage.permissions = background.permissions;
      newBackgroundImage.color = background.color;
      newBackgroundImage.transparency = background.transparency;
      newBackgroundImage.rotation = background.rotation;

      newBackgroundImage.sid = action.sid;
      newBackgroundImage.url = action.url;
      newBackgroundImage.width = action.width;
      newBackgroundImage.height = action.height;

      let parent;
      if (background.isSpreadBackgroundImage()) {
        if (pageElement.hasMultipleBackgroundElements) {
          parent = pageElement;
        } else {
          parent = pageElement.firstChild as BackgroundElement;
        }
      } else {
        // background is ImageElement
        parent = background.parent as BackgroundElement;
        newState.removeElement(background.route);
      }
      removeSpreadBackgroundImage(newState.activeDesign);

      parent.addElement(newBackgroundImage);
      setFillDimensions(parent, newBackgroundImage);
      newState.select(newBackgroundImage.route);
      return newState;
    }

    case CanvasActionTypes.ADD_BACKGROUND_IMAGE: {
      const pageElement = newState.visiblePage;
      const background = pageElement.background;

      // new backgroundimage should not be added when background is image or backgroundimage
      const backgroundImageExists = !!background && !background.isBackgroundElement();

      if (backgroundImageExists || !pageElement.permissions.addChildElements) {
        return newState;
      }

      const newBackgroundImage = !background ? new BackgroundImageElement(-1) : new ImageElement(-1);

      newBackgroundImage.setBackgroundImagePermissions();
      newBackgroundImage.sid = action.sid;
      newBackgroundImage.url = action.url;
      newBackgroundImage.width = action.width;
      newBackgroundImage.height = action.height;

      if (!background) {
        pageElement.addElement(newBackgroundImage);
        setFillDimensions(pageElement, newBackgroundImage);

        pageElement.children
          .filter(element => element.isBackgroundElement() && element.firstChild)
          .map(element => {
            newState.removeElement(element.firstChild.route);
          });
      } else if (background instanceof BackgroundElement) {
        background.addElement(newBackgroundImage);
        setFillDimensions(background, newBackgroundImage);

        removeSpreadBackgroundImage(newState.activeDesign);
      }

      newState.select(newBackgroundImage.route);
      return newState;
    }

    case CanvasActionTypes.CHANGE_TRANSPARENCY: {
      const selectedElement = newState.getElement(action.route);
      selectedElement.transparency = action.transparency;
      return newState;
    }

    case CanvasActionTypes.RESET_IMAGE_COLOR: {
      const selectedElement = newState.getElement(action.route);
      selectedElement.color = '';

      if (!selectedElement.isSpreadBackgroundImage()) {
        setFoilProperties(selectedElement as SpecialColorElement);
      }

      if (selectedElement.isSpecialColorElement() && selectedElement.spotUv) {
        selectedElement.spotUv = false;
      }

      newState.visiblePage.sortChildren();

      return newState;
    }

    case CanvasActionTypes.ADD_CUSTOM_COLOR: {
      const color = action.customColor;
      if (!newState.activeDesign.customColors.includes(color)) {
        newState.activeDesign.customColors.push(color);
      }
      return newState;
    }

    case CanvasActionTypes.ADD_IMAGE: {
      // to prevent adding broken images as element
      // or when it is not allowed to add elements to a page
      if (!action.width || !action.height || !newState.visiblePage.permissions.addChildElements) {
        return newState;
      }
      const activeDesign = newState.activeDesign;
      const newImageElement = createImageElement(action, activeDesign);
      newImageElement.setRegularImagePermissions();

      newImageElement.permissions.hasCoating = activeDesign.material.needsCoating;
      newImageElement.permissions.adjustTransparency = !activeDesign.material.needsCoating;
      newImageElement.permissions.isFoilable = !!action.isFoilable; // not undefined else save service will see a difference

      newImageElement.select();
      newState.addElement(newState.visiblePage.route, newImageElement);
      calculateNewElementPosition(newImageElement, 0);

      return newState;
    }

    case CanvasActionTypes.ADD_IMAGE_AS_PHOTOFRAME: {
      // to prevent adding broken images as element
      // or when it is not allowed to add elements to a page
      if (!action.width || !action.height || !newState.visiblePage.permissions.addChildElements) {
        return newState;
      }

      const activeDesign = newState.activeDesign;
      const newImageElement = createImageElement(action, activeDesign);
      const newPhotoFrameElement = createPhotoFrameElement(newImageElement);

      newPhotoFrameElement.permissions.hasCoating = activeDesign.material.needsCoating;
      newPhotoFrameElement.permissions.adjustTransparency = !activeDesign.material.needsCoating;
      newPhotoFrameElement.permissions.isFoilable = !!action.isFoilable; // not undefined else save service will see a difference
      newPhotoFrameElement.permissions.isSpotUvable = !elementIsJpgImage(newImageElement);

      newPhotoFrameElement.select();
      newState.addElement(newState.visiblePage.route, newPhotoFrameElement);
      calculateNewElementPosition(newPhotoFrameElement, 0);

      return newState;
    }

    case CanvasActionTypes.REPLACE_IMAGE: {
      // to prevent adding broken images as element
      if (!action.width || !action.height) {
        return newState;
      }

      let selectedElement = newState.getElement(action.route);

      if (selectedElement.firstChild) {
        selectedElement = selectedElement as PhotoFrameElement;

        const prevFileName = selectedElement.firstChild.sid || selectedElement.firstChild.url;
        const isPrevSvg = prevFileName ? fileExtensionIsSvg(prevFileName) : true;

        const nextFileName = action.sid || action.url;
        const isNextSvg = nextFileName ? fileExtensionIsSvg(nextFileName) : true;

        selectedElement.firstChild.sid = action.sid;
        selectedElement.firstChild.url = action.url;
        selectedElement.firstChild.width = action.width;
        selectedElement.firstChild.height = action.height;
        selectedElement.naturalHeight = action.naturalHeight;
        selectedElement.naturalWidth = action.naturalWidth;
        selectedElement.firstChild.rotation = 0;
        selectedElement.permissions.hasInstantReplaceablePlaceholder = false;
        selectedElement.select();
        selectedElement.permissions.isFoilable = !!action.isFoilable; // not undefined else save service will see a difference
        selectedElement.firstChild.flipHorizontal = false;
        selectedElement.firstChild.flipVertical = false;

        // Reset foil if next image is not SVG
        if (!isNextSvg) {
          selectedElement.firstChild.foilType = undefined;
        }

        // Reset color when replacing image from/to SVG to another type
        if ((isPrevSvg && !isNextSvg) || (!isPrevSvg && isNextSvg)) {
          selectedElement.firstChild.color = "";
        }

        setFillDimensions(selectedElement, selectedElement.firstChild);
      } else {
        selectedElement = selectedElement as ImageElement;

        const newImgWidth = selectedElement.width;
        const newImgHeight = newImgWidth / (action.width / action.height);
        newState.removeElement(action.route);

        const replacedImageElement = new ImageElement(-1, {
          sid: action.sid,
          url: action.url,
          width: newImgWidth,
          height: newImgHeight,
          x: selectedElement.x,
          y: selectedElement.y
        });
        replacedImageElement.setRegularImagePermissions();
        replacedImageElement.permissions.isFoilable = action.isFoilable;
        replacedImageElement.naturalHeight = action.naturalHeight;
        replacedImageElement.naturalWidth = action.naturalWidth;
        replacedImageElement.permissions.isFoilable = action.isFoilable;
        replacedImageElement.rotation = selectedElement.rotation;
        replacedImageElement.transparency = selectedElement.transparency;
        replacedImageElement.color = selectedElement.color;
        replacedImageElement.order = selectedElement.order;
        if (action.isFoilable) {
          replacedImageElement.foilType = selectedElement.foilType;
        }
        replacedImageElement.select();
        newState.addElement(newState.visiblePage.route, replacedImageElement);
      }

      return newState;
    }

    case CanvasActionTypes.ADD_TEXT: {
      // when it is not allowed to add elements to a page
      if (!newState.visiblePage.permissions.addChildElements) {
        return newState;
      }

      // set default values: when card width is 1000 pixels new textElement
      // has the default new TextElement values
      const textRatio = newState.visiblePage.width / 1000;

      const newTextElement = new TextElement(-1);
      newTextElement.text = action.text;
      newTextElement.width = newTextElement.width * textRatio;
      newTextElement.height = newTextElement.height * textRatio;
      newTextElement.x = newState.visiblePage.width / 2 - newTextElement.width / 2;
      newTextElement.y = newState.visiblePage.height * 0.5;

      newTextElement.font = newState.activeDesign.getMostUsedProperty('font', 'fonts')?.toString() ?? DefaultFont.name;
      newTextElement.color =
        newState.activeDesign.getMostUsedProperty('color', 'colors')?.toString() ?? DefaultFontColor;

      newTextElement.useAbsoluteFontSize = action.useAbsoluteFontSize;
      const mostUsedFontSize = newState.activeDesign.getMostUsedProperty('fontSize', 'fontsizes');
      newTextElement.fontSize = mostUsedFontSize
        ? parseInt(mostUsedFontSize.toString(), 10)
        : setFontSize(newTextElement, textRatio);

      newTextElement.textNotEdited = true;
      newTextElement.permissions.hasCoating = newState.activeDesign.material.needsCoating;
      newTextElement.permissions.adjustTransparency = !newState.activeDesign.material.needsCoating;

      newState.addElement(newState.visiblePage.route, newTextElement);

      calculateNewElementPosition(newTextElement, 0);
      newTextElement.select();
      return newState;
    }

    case CanvasActionTypes.ADD_TEXT_INLINE: {
      // when it is not allowed to add elements to a page
      if (!newState.visiblePage.permissions.addChildElements) {
        return newState;
      }

      const newInlineTextElement = new InlineTextElement(-1);

      const textRatio = newState.visiblePage.width / 1000;
      const inlineTextCorrection = 10;

      const font = DefaultFont;

      const mostUsedFontSize = newState.activeDesign.getMostUsedProperty('fontSize', 'fontsizes');
      let fontsize = DefaultFontSize * (inlineTextCorrection * textRatio);
      fontsize = mostUsedFontSize ? parseInt(mostUsedFontSize.toString(), 10) : fontsize;

      const padding = (newState.visiblePage.width / BASELINE_PAGEWIDTH) * DEFAULT_INLINE_TEXT_PADDING;
      const lineY = padding + font.ascender * fontsize;

      newInlineTextElement.text[0].lines[0].y = coerceNumber(lineY);

      const firstSpan = newInlineTextElement.text[0].lines[0].textSpans[0];
      firstSpan.font = newState.activeDesign.getMostUsedProperty('font', 'fonts')?.toString() ?? font.name;
      firstSpan.fontSize = fontsize;
      firstSpan.text = action.spanText;
      firstSpan.color = newState.activeDesign.getMostUsedProperty('color', 'colors')?.toString() ?? DefaultFontColor;

      newInlineTextElement.width = coerceNumber(newState.visiblePage.width / 2);
      newInlineTextElement.height = coerceNumber((lineY + font.descender * fontsize + padding) / SVG_VIEWBOX_SCALE);
      const { x, y } = getNewElementPosition(
        newState.visiblePage,
        newInlineTextElement.width,
        newInlineTextElement.height
      );
      newInlineTextElement.x = coerceNumber(x);
      newInlineTextElement.y = coerceNumber(y);
      newInlineTextElement.textNotEdited = true;
      newInlineTextElement.editFullRange = false;
      newInlineTextElement.selection.isFocused = true;
      newInlineTextElement.init = true;

      const activeDesign = newState.activeDesign;
      newInlineTextElement.permissions.hasCoating = activeDesign.material.needsCoating;
      newInlineTextElement.permissions.adjustTransparency = !activeDesign.material.needsCoating;

      newState.addElement(newState.visiblePage.route, newInlineTextElement);
      calculateNewElementPosition(newInlineTextElement, 0);
      newInlineTextElement.select();

      return newState;
    }

    case CanvasActionTypes.ADD_BOX: {
      // when it is not allowed to add elements to a page
      if (!newState.visiblePage.permissions.addChildElements) {
        return newState;
      }

      const newBoxElement = new BoxElement(-1);
      newState.addElement(newState.visiblePage.route, newBoxElement);
      calculateNewElementPosition(newBoxElement);

      newBoxElement.select();
      return newState;
    }

    case CanvasActionTypes.PASTE: {
      // when it is not allowed to add elements to a page
      if (!newState.visiblePage.permissions.addChildElements) {
        return newState;
      }

      const newElement = cloneElement(action.newElement);

      newState.addElement(newState.visiblePage.route, newElement);
      if (action.moveElement) {
        calculateNewElementPosition(newElement as ImageElement | TextElement | PhotoFrameElement | InlineTextElement);
      }
      newState.select(newElement.route);

      return newState;
    }

    case CanvasActionTypes.DESELECT: {
      newState.cleanUpAndDeselectElements();
      return newState;
    }

    case CanvasActionTypes.TOGGLE_GRID: {
      newState.showGrid = !newState.showGrid;
      return newState;
    }

    case CanvasActionTypes.FILL_HORIZONTAL: {
      const selectedElement = newState.select(action.route);

      const newFillDimensions = getFillHorizontal(selectedElement, selectedElement.parent);

      const childElementRatio = newFillDimensions.screenWidth / selectedElement.screenWidth;

      selectedElement.screenWidth = newFillDimensions.screenWidth;
      selectedElement.screenHeight = newFillDimensions.screenHeight;
      selectedElement.screenX = newFillDimensions.screenX;
      selectedElement.screenY = newFillDimensions.screenY;
      selectedElement.rotation = 0;

      if (selectedElement.isPhotoFrame()) {
        selectedElement.firstChild.screenWidth = selectedElement.firstChild.screenWidth * childElementRatio;
        selectedElement.firstChild.screenHeight = selectedElement.firstChild.screenHeight * childElementRatio;
        selectedElement.firstChild.screenX = selectedElement.firstChild.screenX * childElementRatio;
        selectedElement.firstChild.screenY = selectedElement.firstChild.screenY * childElementRatio;
      }

      return newState;
    }

    case CanvasActionTypes.FILL_VERTICAL: {
      const selectedElement = newState.select(action.route);

      const newFillDimensions = getFillVertical(selectedElement, selectedElement.parent);
      const childElementRatio = newFillDimensions.screenWidth / selectedElement.screenWidth;

      selectedElement.screenWidth = newFillDimensions.screenWidth;
      selectedElement.screenHeight = newFillDimensions.screenHeight;
      selectedElement.screenX = newFillDimensions.screenX;
      selectedElement.screenY = newFillDimensions.screenY;
      selectedElement.rotation = 0;

      if (selectedElement.isPhotoFrame()) {
        selectedElement.firstChild.screenWidth = selectedElement.firstChild.screenWidth * childElementRatio;
        selectedElement.firstChild.screenHeight = selectedElement.firstChild.screenHeight * childElementRatio;
        selectedElement.firstChild.screenX = selectedElement.firstChild.screenX * childElementRatio;
        selectedElement.firstChild.screenY = selectedElement.firstChild.screenY * childElementRatio;
      }

      return newState;
    }

    case CanvasActionTypes.UPDATE_IMAGE: {
      updateImage(newState, action.element, action.route, action.imgSource, action.isVectorImage);
      return newState;
    }

    case CanvasActionTypes.INIT_VISIBLE_PAGE_IMAGES: {
      if (!newState.initialPageImagesLoaded) {
        newState.initialPageImagesLoaded = true;
      } else {
        const page: PageElement = newState.getElement(action.route) as PageElement;
        page.init = true;
      }
      return newState;
    }

    case CanvasActionTypes.UPDATE_ALL_SPECIAL_COLOR_ELEMENTS: {
      const design = newState.activeDesign;
      let selectedElement = design.getElement(action.route) as SpecialColorElement;

      if (selectedElement.isPhotoFrameChild) {
        selectedElement = selectedElement.parent;
      }

      // All the same types of elements get spotUv
      if (action.color.spotUv) {
        const selectedElementIsJpg = elementIsJpgImage(selectedElement);

        [selectedElement, ...design.foilElements].map(element => {
          element.foilType = undefined;
          if (!element.permissions.isSpotUvable) {
            return;
          }

          const elementIsJpg = elementIsJpgImage(element);
          if (!selectedElementIsJpg && !elementIsJpg) {
            //foilelements get spotUvColor
            element.color = action.color.colorValue;
          }
          element.spotUv = selectedElementIsJpg === elementIsJpg && action.color.spotUv;
        });

        [selectedElement, ...design.spotUvElements].map(element => {
          if (!element.permissions.isSpotUvable) {
            return;
          }
          element.spotUv = selectedElementIsJpg === elementIsJpgImage(element) && action.color.spotUv;
        });

        // All foilable elements get foil
      } else if (action.color.foil) {
        [selectedElement, ...design.foilElements, ...design.spotUvElements].map(element => {
          element.spotUv = false;
          if (!element.permissions.isFoilable) {
            return;
          }
          setFoilProperties(element, action.color.foil);
          element.color = action.color.colorValue;
        });
      } else {
        selectedElement.spotUv = false;
        [selectedElement, ...design.foilElements].map(element => {
          element.foilType = null;
          element.color = action.color.colorValue;
        });
      }

      newState.visiblePage.sortChildren();
      return newState;
    }

    case CanvasActionTypes.RESET_ALL_SPOT_UV_ELEMENTS: {
      const activeDesign = newState.activeDesign;

      activeDesign.spotUvElements.map(element => (element.spotUv = false));

      return newState;
    }

    case CanvasActionTypes.TOGGLE_VIEW: {
      const activeDesign = newState.activeDesign;
      if (action.view !== View.pages) {
        // first set view to pages before toggling to spreadview
        activeDesign.updateView(View.pages);
        activeDesign.spreads = getSpreads(activeDesign, action.view);
      }

      activeDesign.updateView(action.view);

      if (action.view === View.pages) {
        // when toggling to pageview, newState.spreads must be set to prevent that all corners are round
        activeDesign.spreads = getSpreads(activeDesign, action.view);
      }

      fillPage(newState, activeDesign.pages[0]);

      return newState;
    }

    case CanvasActionTypes.ADD_TAG: {
      const selectedElement = newState.getElement(action.route);
      selectedElement.tag = action.tag;
      return newState;
    }

    case CanvasActionTypes.SET_LABEL: {
      const activeDesign = newState.activeDesign;
      activeDesign.visiblePage.label[action.index] = action.value;
      activeDesign.labels[activeDesign.view] = activeDesign.pages.map(page => page.label);

      return newState;
    }

    case CanvasActionTypes.CONVERT_TO_INLINE_TEXT: {
      const newInlineTextElement = cloneDeep(action.inlineTextElement);
      const parent = newState.getElement(action.route.slice(1, action.route.length)) as PageElement;
      newState.removeElement(action.route);
      parent.addElement(newInlineTextElement, action.inlineTextElement.id, action.inlineTextElement.order);
      return newState;
    }

    case CanvasActionTypes.CHANGE_TRIM: {
      newState.activeDesign.trimType = action.trim.type;

      return newState;
    }

    case CanvasActionTypes.SET_MATERIAL: {
      newState.activeDesign.material = Object.assign(new Material(), action.material);

      // add material background image
      if (action.material.image) {
        newState.activeDesign.pages.forEach(page =>
          page.backgroundElements.forEach(bg => {
            const newBgImage = new ImageElement(-1);
            newBgImage.sid = action.material.image;
            newBgImage.width = 100;
            newBgImage.height = 100;
            bg.addElement(newBgImage);
            setFillDimensions(bg, newBgImage);
            removeSpreadBackgroundImage(newState.activeDesign);
            bg.color = action.material.image ? '#ffffff' : bg.color;

            // lock all backgrounds
            bg.children.forEach(bgImage => {
              bgImage.permissions.isLocked = !!action.material.image;
              bgImage.permissions.isCroppable = !action.material.image;
              bgImage.permissions.isRotatable = !action.material.image;
              bgImage.permissions.isMovable = !action.material.image;
              bgImage.permissions.isResizable = !action.material.image;
              bgImage.permissions.isPrintable = !action.material.image;
              bgImage.permissions.adjustTransparency = !action.material.image;
            });
            bg.permissions.isLocked = !!action.material.image;
          })
        );
      } else {
        newState.activeDesign.pages.forEach(page =>
          page.backgroundElements.forEach(bg => {
            bg.permissions.isLocked = false;
            bg.children = [];
          })
        );
      }

      // toggle coating
      newState.activeDesign.pages.forEach(page =>
        page.children.forEach(element => {
          if (element.isPhotoFrame() || element.isText() || element.isInlineText() || element.isImage()) {
            element.permissions.isFoilable = action.material.isFoilable ? element.permissions.isFoilable : false;
            element.permissions.isSpotUvable = action.material.isSpotUvable ? element.permissions.isSpotUvable : false;
            element.permissions.hasCoating = action.material.needsCoating;
            if (action.material.needsCoating) {
              element.permissions.adjustTransparency = false;
              if (element.isPhotoFrame()) {
                element.firstChild.transparency = 0;
              } else {
                element.transparency = 0;
              }
            }
          }
        })
      );

      if (!action.material.isFoilable) {
        newState.activeDesign.foilElements.map(element => {
          setFoilProperties(element);
        });
      }

      if (!action.material.isSpotUvable) {
        newState.activeDesign.spotUvElements.map(el => {
          el.spotUv = false;
        });
      }

      return newState;
    }

    case CanvasActionTypes.UPDATE_DESIGN_IMAGES: {
      newState.designs.find(d => d.setId === action.setId).designImages = action.images;
      return newState;
    }

    case CanvasActionTypes.UPDATE_DESIGN_COLORS: {
      newState.designs.find(d => d.setId === action.setId).designColors = action.colors;
      return newState;
    }

    case CanvasActionTypes.UPDATE_FOIL_PERMISSION_WAS_CHANGED: {
      getFlatChildren(newState.activeDesign.pages).forEach(element => {
        if (action.enableFoilableByDefault && element.permissions.isFoilable === false) {
          // only false, if undefined nothing should happen
          element.isFoilablePermissionWasChanged = true;
        } else if (!action.enableFoilableByDefault && element.permissions.isFoilable === true) {
          element.isFoilablePermissionWasChanged = true;
        } else {
          element.isFoilablePermissionWasChanged = false;
        }
      });

      return newState;
    }

    case CanvasActionTypes.UPDATE_SPOTUV_FOR_JPG_PERMISSION_WAS_CHANGED: {
      getFlatChildren(newState.activeDesign.pages).forEach(element => {
        if (element.isPhotoFrame() && element.firstChild.isJpg) {
          element.isSpotUvForJpgImagePermissionWasChanged =
            action.spotUvPermissionForJpgByDefault !== element.permissions.isSpotUvable;
        }
      });

      return newState;
    }

    case CanvasActionTypes.TOGGLE_TARGETABLE_WHEN_TARGETABLE: {
      newState.untargetableElementsTargetable = !newState.untargetableElementsTargetable;
      return newState;
    }

    case CanvasActionTypes.SET_LAYER_TYPE: {
      const newElement = newState.getElement(action.route) as LayerableElement;
      newElement.layerType = action.layerType;
      if (action.layerType !== LayerType.standardRgb) {
        newElement.spotUv = false;
        newElement.foilType = undefined;
      }
      return newState;
    }

    case CanvasActionTypes.SET_CHARACTER_LIMIT: {
      const element = newState.getElement(action.route) as InlineTextElement;
      element.characterLimit = action.characterLimit;
      return newState;
    }

    default: {
      return state;
    }
  }
}

function calculateNewElementPosition(
  element: ImageElement | TextElement | InlineTextElement | BoxElement | PhotoFrameElement,
  offSet?: number,
  delta?: number
): void {
  offSet = offSet !== undefined ? offSet : element.parent.width / 40;
  delta = delta !== undefined ? delta : element.parent.width / 40;

  element.x = roundToHalf(element.x + offSet);
  element.y = roundToHalf(element.y + offSet);

  while (elementFound(element)) {
    element.x = roundToHalf(element.x + delta);
    element.y = roundToHalf(element.y + delta);
  }
}

function elementFound(element: CanvasElement) {
  return (
    element.parent.children.findIndex(
      (sibling: CanvasElement) =>
        roundToHalf(sibling.x) === element.x && roundToHalf(sibling.y) === element.y && sibling.id !== element.id
    ) > -1
  );
}

function setFontSize(element: TextElement, ratio: number): number {
  const absoluteFontSizeRatio = 3.5;
  const textRatio = element.useAbsoluteFontSize ? ratio * absoluteFontSizeRatio : ratio;

  return Math.ceil(element.fontSize * textRatio);
}

function setFoilProperties(element: SpecialColorElement, foil?: FoilTypes) {
  if (foil) {
    if (element.isPhotoFrame()) {
      element.firstChild.transparency = 0;
    } else {
      element.transparency = 0;
    }
  }

  element.foilType = foil;
}

function getNewElementPosition(page: PageElement, elementWidth: number, elementHeight: number) {
  const correctionY = page.height * 0.05; // correction to add element a bit above middle of canvas
  const x = page.width / 2 - elementWidth / 2;
  const y = page.height / 2 - elementHeight / 2 - correctionY;
  return { x, y };
}

function createImageElement(action: CanvasActions.AddImage | CanvasActions.AddImageAsPhotoFrame, design: Design) {
  const widthFactor = action.width / (design.visiblePage.width / 2);
  const heightFactor = action.height / (design.visiblePage.height / 2);
  const imageRatio = widthFactor >= heightFactor ? widthFactor : heightFactor;
  const newWidth = action.width / imageRatio;
  const newHeight = action.height / imageRatio;
  const { x, y } = getNewElementPosition(design.visiblePage, newWidth, newHeight);
  const newX = action.x - newWidth / 2 || x;
  const newY = action.y - newHeight / 2 || y;
  const newImageElement = new ImageElement(-1, {
    sid: action.sid,
    url: action.url,
    width: newWidth,
    height: newHeight,
    x: newX,
    y: newY
  });

  // naturalHeight/Width can be used for calculating maxsize for request and if resolution is high enough for page size
  // but before we can use it all img lib api's should include width/height in response
  newImageElement.naturalHeight = action.height;
  newImageElement.naturalWidth = action.width;

  return newImageElement;
}

function createPhotoFrameElement(imageElement: ImageElement) {
  const newPhotoFrameElement = new PhotoFrameElement(-1, {
    width: imageElement.width,
    height: imageElement.height,
    x: imageElement.x,
    y: imageElement.y
  });
  imageElement.x = 0;
  imageElement.y = 0;
  newPhotoFrameElement.addElement(imageElement);

  return newPhotoFrameElement;
}

function getNewScreenDimensions(action: CanvasActions.Resize | CanvasActions.Crop, selectedElement: CanvasElement) {
  // convert screenX to x and screenY to y
  const x = action.x;
  const y = action.y;

  // round x and y and convert them back to screenX and screenY
  const newScreenX = roundToHalf(x);
  const newScreenY = roundToHalf(y);

  // difference between unrounded and rounded value of x
  const correctionX = x - roundToHalf(x);
  const correctionY = y - roundToHalf(y);

  // calculate newScreenWidth based on corrected width
  const width = action.width;
  const newWidth = width + correctionX;
  const newScreenWidth = newWidth;

  // calculate newScreenHeight based on corrected height
  const height = action.height;
  const newHeight = height + correctionY;
  const newScreenHeight = newHeight;

  return { newScreenX, newScreenY, newScreenWidth, newScreenHeight };
}

function removeSpreadBackgroundImage(design: Design) {
  if (design.spreadView) {
    // in spreadview, remove the spreadBackgroundImage from the visiblePage
    design.visiblePage.children = design.visiblePage.children.filter(el => !el.isSpreadBackgroundImage());

    // hack for cardview: remove spreadBackgroundImage from first and last spreadPage when visiblePage has one backgroundElement
    if (design.view === View.card && !design.visiblePage.hasMultipleBackgroundElements) {
      const spreadIndices = [0, design.spreads.length - 1]; // index of first and last spread
      spreadIndices.map(index => {
        design.spreads[index].spreadPage.children = design.spreads[index].spreadPage.children.filter(
          el => !el.isSpreadBackgroundImage()
        );
      });
    }
  } else {
    // in pageview, remove spreadBackgroundImage from all pages that are in the same spread as the visiblePage
    const visibleSpread = design.spreads.find(spread => !!spread.pages.find(page => page.visible));
    visibleSpread.pages.map(page => (page.children = page.children.filter(el => !el.isSpreadBackgroundImage())));
  }

  return design;
}

function updateImage(
  newState: DesignSet,
  element: CanvasElement,
  route: number[],
  imgSource: string,
  isVectorImage: boolean
): void {
  const selectedElement = newState.getElement(route);

  if (selectedElement) {
    selectedElement.height = element.height;
    selectedElement.width = element.width;
    selectedElement.x = element.x;
    selectedElement.y = element.y;

    selectedElement.rotation = element.rotation;
    selectedElement.imgSource = imgSource;

    if (selectedElement.isImage()) {
      (selectedElement as ImageElement).isVectorImage = isVectorImage;
    }

    let storeParent = selectedElement.parent;
    let parent = element.parent;
    while (parent) {
      storeParent.height = parent.height;
      storeParent.width = parent.width;
      storeParent.x = parent.x;
      storeParent.y = parent.y;
      storeParent.rotation = parent.rotation;

      if (parent.route.length > 1) {
        storeParent = storeParent.parent;
        parent = parent.parent;
      } else {
        parent = null;
      }
    }
  }
}
