import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import { Subject, takeUntil } from 'rxjs';
import { CanvasActions } from 'src/app/actions';
import { ImageElement, InlineTextElement, TextElement } from 'src/app/models';
import { AppState } from 'src/app/reducers';
import { CacheElement, ElementCachingService, ImageResponse, elementCachingServiceProvider } from 'src/app/services/cache.service';
import { CanvasUiService } from '../canvas/services/canvas-ui.service';

@Component({
  selector: 'ed-image',
  template: '',
  providers: [elementCachingServiceProvider],
})
export class ImageComponent implements OnInit, OnDestroy {
  private oldImageParams: Record<string, unknown> = {};
  private oldMaxSize: number = 0;
  private imageSource: SafeUrl;

  protected readonly unsubscribe$ = new Subject<void>();

  @Input()
  element: ImageElement | TextElement | InlineTextElement;

  @Input()
  useInlineText: boolean;

  @Input()
  set imgParams(params: Record<string, unknown>) {
    if (
      !this.canvasUiService.isModifyingObject &&
      !isEqual(this.oldImageParams, params)
    ) {
      this.updateImage();
      this.oldImageParams = params;
    }
  }

  @Input()
  set maxSize(size: number) {
    if (
      !this.canvasUiService.isModifyingObject &&
      this.oldMaxSize <= size
    ) {
      this.updateImage();
      this.oldMaxSize = size;
    }
  }

  @Input()
  set imgSource(imgSource: string) {
    if (imgSource) {
      this.imageSource = this.sanitizer.bypassSecurityTrustResourceUrl(imgSource);
    } else if (!this.canvasUiService.isModifyingObject) {
      this.updateImage();
    }
  }

  constructor(
    private sanitizer: DomSanitizer,
    private store: Store<AppState>,
    private elementCachingService: ElementCachingService,
    private canvasUiService: CanvasUiService,
  ) { }

  ngOnInit() {
    let notLoaded = this.useInlineText && this.element.isText();
    if (notLoaded) {
      return;
    }

    this.elementCachingService.image$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((cacheItem: ImageResponse) => {
        this.store.dispatch(
          new CanvasActions.UpdateImageAction(
            this.element.route,
            this.element,
            cacheItem.url,
            cacheItem.isVectorImage
          )
        );
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private updateImage() {
    if (this.element.isText() || this.element.isImage() || this.element.isInlineText()) {
      this.elementCachingService.element.next(this.element as CacheElement);
    }
  }
}
