import { FabricCanvasActionTypes } from "./action.types";
import { ChangeInlineTextFontFamily } from "./change-inline-text-font-family.action";
import { ChangeInlineTextFontSize } from "./change-inline-text-font-size.action";
import { ChangeInlineTextFontStyle } from "./change-inline-text-font-style.action";
import { ChangeInlineTextFontWeight } from "./change-inline-text-font-weight.action";
import { ChangeInlineTextLineHeight } from "./change-inline-text-line-height.action";
import { ChangeInlineTextOpacity } from "./change-inline-text-opacity.action";
import { ChangeInlineTextTextAlign } from "./change-inline-text-text-align.action";
import { ChangeInlineTextUnderline } from "./change-inline-text-underline.action";
import { ChangePhotoFrameZoom } from "./change-photo-frame-zoom.action";

export const types = FabricCanvasActionTypes;

type All =
  | ChangeInlineTextFontFamily
  | ChangeInlineTextFontSize
  | ChangeInlineTextFontStyle
  | ChangeInlineTextFontWeight
  | ChangeInlineTextLineHeight
  | ChangeInlineTextTextAlign
  | ChangeInlineTextUnderline
  | ChangeInlineTextOpacity
  | ChangePhotoFrameZoom;

type AllInlineText =
  | ChangeInlineTextFontFamily
  | ChangeInlineTextFontSize
  | ChangeInlineTextFontStyle
  | ChangeInlineTextFontWeight
  | ChangeInlineTextLineHeight
  | ChangeInlineTextTextAlign
  | ChangeInlineTextUnderline
  | ChangeInlineTextOpacity;

type AllPhotoFrame =
  | ChangePhotoFrameZoom;

export {
  All,
  AllInlineText,
  AllPhotoFrame,
  ChangeInlineTextFontFamily,
  ChangeInlineTextFontSize,
  ChangeInlineTextFontStyle,
  ChangeInlineTextFontWeight,
  ChangeInlineTextLineHeight,
  ChangeInlineTextOpacity,
  ChangeInlineTextTextAlign,
  ChangeInlineTextUnderline,
  ChangePhotoFrameZoom
};
