/**
 * Fabric object route property name.
 */
export const X_ROUTE = 'x-route';

/**
 * Fabric object replace control route property name.
 */
export const X_REPLACE_CONTROL_ROUTE = 'x-replace-control-route';

/**
 * Fabric object crop route property name.
 */
export const X_CROP_ROUTE = 'x-crop-route';

/**
 * Fabric object text image route property name.
 */
export const X_TEXT_IMAGE_ROUTE = 'x-text-image-route';

/**
 * Fabric object coat route property name.
 */
export const X_COAT_ROUTE = 'x-coat-route';

/**
 * Fabric object cut through route property name.
 */
export const X_CUT_THROUGH_ROUTE = 'x-cut-through-route';

/**
 * Fabric object special color (`foil`, `spot-uv`) route property name.
 */
export const X_SPECIAL_COLOR_ROUTE = 'x-special-color-route';

/**
 * Fabric object temp key previx.
 */
export const X_TEMP_KEY_PREFIX = 'x-temp-';

/**
 * Fabric object temp transform matrix property name.
 */
export const X_TRANSFORM_MATRIX = 'x-transform-matrix';

/**
 * Fabric object temp transform property name.
 */
export const X_TEMP_TRANSFORM = X_TEMP_KEY_PREFIX + 'transform';

/**
 * Fabric object temp setup object before modifying property name.
 */
export const X_TEMP_SETUP_OBJECT_BEFORE_MODIFYING = X_TEMP_KEY_PREFIX + "setup-object-before-modifying";

/**
 * Fabric object temp before transform index property name.
 */
export const X_TEMP_BEFORE_TRANSFORM_INDEX = X_TEMP_KEY_PREFIX + "before-transform-index";

/**
 * Fabric object temp exclude from cut through property name.
 */
export const X_TEMP_EXCLUDE_FROM_CUT_THROUGH = X_TEMP_KEY_PREFIX + "exclude-from-cut-through";

/**
 * Fabric object temp exclude from special color (`foil`, `spot-uv`) property name.
 */
export const X_TEMP_EXCLUDE_FROM_SPECIAL_COLOR = X_TEMP_KEY_PREFIX + "exclude-from-special-color";

/**
 * Fabric object temp original property name.
 */
export const X_TEMP_ORIGINAL = X_TEMP_KEY_PREFIX + "original";

/**
 * Fabric object temp mouse down pointer property name.
 */
export const X_TEMP_MOUSE_DOWN_POINTER = X_TEMP_KEY_PREFIX + "mouse down pointer";
