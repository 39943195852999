import { EditorAction } from '../action';
import { ACTIVATE_OVERLAY } from './canvas.action.types';

export class ActivateOverlay implements EditorAction {
  readonly type = ACTIVATE_OVERLAY;
  readonly undoable = false;
  readonly save = false;

  constructor(public route: Array<number>, public x: number, public y: number) {}
}
