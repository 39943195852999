import { fabric } from "fabric";
import { BaseElement, CanvasElement, FoilTypes, PageElement } from "src/app/models";
import { ControlsVisibility } from "../controls-visibility";

declare module "src/app/models/page-element" {
  interface PageElement {
    getCutThroughBehindPages(): PageElement[];
    getCutThroughElements(inverted: boolean): CanvasElement[];
    getFoilElements(): CanvasElement[];
    getFoilType(): FoilTypes;
    getSpotUvElements(): CanvasElement[];
  }
}

PageElement.prototype.createObjectAsync = async function (object?: fabric.Object, data?: any): Promise<fabric.Object> {
  const element: PageElement = this;

  if (!(element instanceof PageElement)) {
    return null;
  }

  object ??= new fabric.Rect({
    // @ts-expect-error
    _render: _render_roundCorners,
  });

  if (!(object instanceof fabric.Rect)) {
    return null;
  }

  const objectOptions = element.getObjectOptions(object);
  object.set(objectOptions);

  const controlsVisibility = element.getObjectControlsVisibility(object);
  object.setControlsVisibility(controlsVisibility);

  return object;
}

PageElement.prototype.getObjectOptions = function (object: fabric.Object): fabric.IObjectOptions {
  const element: PageElement = this;

  if (!(element instanceof PageElement) || !(object instanceof fabric.Rect)) {
    return null;
  }

  return {
    ...BaseElement.prototype.getObjectOptions.call(element, object),
    width: element.width,
    height: element.height,
    scaleX: 1,
    scaleY: 1,
    fill: element.backgroundColor,
    selectable: false,
    rtl: (element.parent.hasRoundedCorners && element.borderRadius.leftTop) || 0,
    rtr: (element.parent.hasRoundedCorners && element.borderRadius.rightTop) || 0,
    rbr: (element.parent.hasRoundedCorners && element.borderRadius.rightBottom) || 0,
    rbl: (element.parent.hasRoundedCorners && element.borderRadius.leftBottom) || 0
  };
}

PageElement.prototype.getObjectControlsVisibility = function (object: fabric.Object): ControlsVisibility {
  const element: PageElement = this;

  if (!(element instanceof PageElement) || !(object instanceof fabric.Rect)) {
    return null;
  }

  return {
    ...BaseElement.prototype.getObjectControlsVisibility.call(element, object),
  };
}

PageElement.prototype.getCutThroughBehindPages = function (): PageElement[] {
  const element: PageElement = this;

  if (
    !(element instanceof PageElement) ||
    !element.pageBehindId ||
    !element.getCutThroughElements(false) ||
    !element.getCutThroughElements(true)
  ) {
    return [];
  }

  const behindPage = element.parent.pages.find((p) => p.id == element.pageBehindId);
  return [...behindPage.getCutThroughBehindPages(), behindPage];
}

PageElement.prototype.getCutThroughElements = function (inverted: boolean): CanvasElement[] {
  const element: PageElement = this;

  if (!(element instanceof PageElement)) {
    return [];
  }

  return element.children.filter((e) => inverted ? e.isCutThroughInverted : e.isCutThrough);
}

PageElement.prototype.getFoilElements = function (): CanvasElement[] {
  const element: PageElement = this;

  if (!(element instanceof PageElement)) {
    return [];
  }

  return getElements(element.children);

  function getElements(elements: CanvasElement[]): CanvasElement[] {
    return elements.filter((e) => e.isSpecialColorElement() && (e.foilType || getElements(e.children).length));
  }
}

PageElement.prototype.getFoilType = function (): FoilTypes {
  const element: PageElement = this;

  if (!(element instanceof PageElement)) {
    return null;
  }

  return getFoilType(element.children);

  function getFoilType(elements: CanvasElement[]): FoilTypes {
    return elements.reduce((foilType: FoilTypes, e) => foilType || (e.isSpecialColorElement() && e.foilType) || getFoilType(e.children), undefined);
  }
}

PageElement.prototype.getSpotUvElements = function (): CanvasElement[] {
  const element: PageElement = this;

  if (!(element instanceof PageElement)) {
    return [];
  }

  return getElements(element.children);

  function getElements(elements: CanvasElement[]): CanvasElement[] {
    return elements.filter((e) => e.isSpecialColorElement() && (e.spotUv || getElements(e.children).length));
  }
}

//#region Override

function _render_roundCorners(ctx: CanvasRenderingContext2D) {

  // 1x1 case (used in spray brush) optimization was removed because
  // with caching and higher zoom level this makes more damage than help

  var rx = this.rx ? Math.min(this.rx, this.width / 2) : 0,
    ry = this.ry ? Math.min(this.ry, this.height / 2) : 0,
    rtl = this.rtl ? Math.min(this.rtl, this.width / 2, this.height / 2) : 0,
    rtr = this.rtr ? Math.min(this.rtr, this.width / 2, this.height / 2) : 0,
    rbr = this.rbr ? Math.min(this.rbr, this.width / 2, this.height / 2) : 0,
    rbl = this.rbl ? Math.min(this.rbl, this.width / 2, this.height / 2) : 0,
    w = this.width,
    h = this.height,
    x = -this.width / 2,
    y = -this.height / 2,
    isRounded = rx !== 0 || ry !== 0 || rtl !== 0 || rtr !== 0 || rbr !== 0 || rbl !== 0,
    /* "magic number" for bezier approximations of arcs (http://itc.ktu.lt/itc354/Riskus354.pdf) */
    k = 1 - 0.5522847498;

  ctx.beginPath();

  ctx.moveTo(x + (rx || rtl), y);

  ctx.lineTo(x + w - (rx || rtr), y);
  isRounded && ctx.bezierCurveTo(x + w - k * (rx || rtr), y, x + w, y + k * (ry || rtr), x + w, y + (ry || rtr));

  ctx.lineTo(x + w, y + h - (ry || rbr));
  isRounded && ctx.bezierCurveTo(x + w, y + h - k * (ry || rbr), x + w - k * (rx || rbr), y + h, x + w - (rx || rbr), y + h);

  ctx.lineTo(x + (rx || rbl), y + h);
  isRounded && ctx.bezierCurveTo(x + k * (rx || rbl), y + h, x, y + h - k * (ry || rbl), x, y + h - (ry || rbl));

  ctx.lineTo(x, y + (ry || rtl));
  isRounded && ctx.bezierCurveTo(x, y + k * (ry || rtl), x + k * (rx || rtl), y, x + (rx || rtl), y);

  ctx.closePath();

  this._renderPaintInOrder(ctx);
}

//#endregion
