import { CanvasElement } from "src/app/models";
import { coerceNumber } from "src/app/utils/element.utils";

export function getChildrenFullRange(element: CanvasElement): CanvasElement[] {
  if (!element) {
    return [];
  }
  return [element, ...element.children.flatMap((childElement) => getChildrenFullRange(childElement))];
}

export function getRotation(angle: number): number {
  return coerceNumber(angle > 180 ? (180 - (angle - 180)) * -1 : angle, 0);
}

export function hasCoating(element: CanvasElement) {
  return (
    (element.isImage() && !element.isVectorImage && element.permissions.hasCoating) ||
    (element.isPhotoFrame() && !element.firstChild.isVectorImage && element.combinedPermissions.hasCoating)
  );
}
