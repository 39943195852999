import { fabric } from "fabric";

export function getUpperCanvasElement(canvas: fabric.Canvas | undefined): Element | undefined {
  if (!canvas) {
    return;
  }

  const canvasElement = canvas.getElement();
  const parentElement = canvasElement.parentElement;
  return parentElement.getElementsByClassName('upper-canvas')[0];
}
