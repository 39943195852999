import { Injectable } from '@angular/core';
import {
  BackgroundElement,
  BackgroundImageElement,
  BackgroundTypes,
  BoxElement,
  CanvasElement,
  CategoryImage,
  Color,
  defaultMaterialType,
  Design,
  EditorPermissions,
  Fold,
  ImageElement,
  KcBackground,
  KcDesign,
  KcDesignData,
  KcElementType,
  KcImageElement,
  KcLayerType,
  KcPage,
  KcRichTextElement,
  KcTextElement,
  Material,
  PageElement,
  PhotoFrameElement,
  Trim,
  TrimTypes,
  View
} from '../models';
import { ElementsIntersectService } from '../services/elements-intersect.service';
import { GetTextService } from '../services/get-text.service';
import { setFillDimensions } from '../utils/fill.utils';
import { getSpreads } from '../utils/spreads.utils';
import { AppState } from '../reducers';
import { select, Store } from '@ngrx/store';
import { cloneDeep, uniq } from 'lodash-es';
import { combineLatest } from 'rxjs';
import { selectPermissionsState } from '../reducers/permissions.reducer';
import {
  addCommonProperties,
  addElementPermissions,
  convertToLayerType,
  createCanvasElement,
  createParentElementFromClipPath,
  getLayerType,
  setElementPosition,
  setImageDimensions
} from '../utils/transform.utils';
import { fileExtensionIsJpg } from '../utils/element.utils';

@Injectable()
export class TransformService {
  constructor(
    private intersectService: ElementsIntersectService,
    private getTextService: GetTextService,
    private store: Store<AppState>
  ) {
    combineLatest([this.store.pipe(select(selectPermissionsState)), this.store.pipe(select(s => s.config))]).subscribe(
      ([permissionState, config]) => {
        this.materials = config.materials;
        this.trimData = config.trimData;
        this.foilColors = config.foilColorData;
        this.spotUvColors = config.spotUvColorData;

        this.useAbsoluteFontSizeConfig = config.useAbsoluteFontSize;

        // cannot use merged permission state because design permissions are not initialised on the moment design is created from json
        this.canChangeBorderTrim =
          permissionState.userPermissions.functionPermissions.canChangeBorderTrim &&
          permissionState.configPermissions.functionPermissions.canChangeBorderTrim;
        this.canChangeBackgroundColor =
          permissionState.userPermissions.functionPermissions.canChangeBackgroundColor &&
          permissionState.configPermissions.functionPermissions.canChangeBackgroundColor;
        this.addImageAsPhotoFrame =
          permissionState.userPermissions.functionPermissions.addImageAsPhotoFrame &&
          permissionState.configPermissions.functionPermissions.addImageAsPhotoFrame;
        this.enableFoilableByDefault =
          permissionState.userPermissions.functionPermissions.enableFoilableByDefault &&
          permissionState.configPermissions.functionPermissions.enableFoilableByDefault;
        this.spotUvPermissionForJpgByDefault =
          permissionState.userPermissions.functionPermissions.spotUvPermissionForJpgByDefault &&
          permissionState.configPermissions.functionPermissions.spotUvPermissionForJpgByDefault;
        this.canAddSpotUv =
          permissionState.userPermissions.functionPermissions.canAddSpotUv &&
          permissionState.configPermissions.functionPermissions.canAddSpotUv;
      }
    );
  }

  materials: Material[] = [];
  trimData: Trim[] = [];
  useAbsoluteFontSizeConfig: boolean;
  foilColors: Color[];
  spotUvColors: Color[];
  view: View;

  canChangeBorderTrim: boolean;
  canChangeBackgroundColor: boolean;
  addImageAsPhotoFrame: boolean;
  enableFoilableByDefault: boolean;
  spotUvPermissionForJpgByDefault: boolean;
  canAddSpotUv: boolean;

  transformKcPages(pages: KcDesign, useAbsoluteFontSizeDesign = false, editorPermissions?: EditorPermissions): Design {
    // get design permissions directly from design json, when undefined, default to true
    this.canChangeBorderTrim =
      this.canChangeBorderTrim && editorPermissions?.functionPermissions?.canChangeBorderTrim !== false;
    this.canChangeBackgroundColor =
      this.canChangeBackgroundColor && editorPermissions?.functionPermissions?.canChangeBackgroundColor !== false;
    this.addImageAsPhotoFrame =
      this.addImageAsPhotoFrame && editorPermissions?.functionPermissions?.addImageAsPhotoFrame !== false;
    this.enableFoilableByDefault =
      this.enableFoilableByDefault && editorPermissions?.functionPermissions?.enableFoilableByDefault !== false;
    this.spotUvPermissionForJpgByDefault =
      this.spotUvPermissionForJpgByDefault &&
      editorPermissions?.functionPermissions?.spotUvPermissionForJpgByDefault !== false;
    this.canAddSpotUv = this.canAddSpotUv && editorPermissions?.functionPermissions?.canAddSpotUv !== false;

    const design = new Design();
    if (pages._version === undefined || pages._version !== design._version) {
      console.warn(
        `incompatible design version, editor is expecting version: ${design._version}, version is : ${pages._version}`
      );
    }
    const designData: KcDesignData =
      pages.design_data !== undefined ? (pages.design_data as KcDesignData) : new KcDesignData();
    const designView = designData.view ? designData.view : View.pages;
    design.foldType = designData.fold || Fold.none;
    design.trimType = designData.trim || TrimTypes.borderStraight;
    const materialType =
      designData.material && designData.material.type ? designData.material.type : defaultMaterialType;
    const configMaterial = this.materials.find(material => material.type === materialType);
    design.material = configMaterial
      ? { ...new Material(), ...configMaterial }
      : { ...new Material(), ...designData.material };

    if (design.material.type === defaultMaterialType) {
      // overwrite not foilaible default materials
      design.material = new Material();
    }

    design.specs = designData.specs ? JSON.stringify(designData.specs) : undefined;

    const sortedPageNames = this.getSortedPageNames(pages, designData);

    design.adjustTrimPermissionWasChanged =
      designData.adjust_trim_permission_was_changed !== undefined
        ? designData.adjust_trim_permission_was_changed
        : design.adjustTrimPermissionWasChanged;
    design.changeBackgroundColorPermissionWasChanged =
      designData.change_background_color_permission_was_changed !== undefined
        ? designData.change_background_color_permission_was_changed
        : design.changeBackgroundColorPermissionWasChanged;
    design.designImages = (designData.design_images || []).map(i => new CategoryImage(i.name, i.sid, i.url));
    design.designColors = (designData.design_colors || []).map(c => new Color(c.name, c.color_value));
    if (!design.designColors.length) {
      design.designColors = this.getDeprecatedDesignColors(pages, sortedPageNames);
    }

    const p = designData.permissions;

    design.permissions.adjustTrim =
      this.canChangeBorderTrim && !design.adjustTrimPermissionWasChanged
        ? true
        : p && p.adjustTrim !== undefined
          ? p.adjustTrim
          : design.permissions.adjustTrim;

    design.permissions.changeBackgroundColor =
      this.canChangeBackgroundColor && !design.changeBackgroundColorPermissionWasChanged
        ? true
        : p && p.changeBackgroundColor !== undefined
          ? p.changeBackgroundColor
          : design.permissions.changeBackgroundColor;

    design.permissions.changeMaterial =
      p && p.changeMaterial !== undefined ? p.changeMaterial : design.permissions.changeMaterial;
    design.permissions.useRichText = p && p.useRichText !== undefined ? p.useRichText : design.permissions.useRichText;

    design.editorPermissions = editorPermissions;
    this.setDesignLabels(design, designData, sortedPageNames.length);

    sortedPageNames.map((pageName, index) => {
      const page = pages[pageName] as KcPage;
      if (pages._version === undefined || pages._version < 1) {
        convertToLayerType(page, 'is_cut_through', KcLayerType.cutThrough);
        convertToLayerType(page, 'cut_through_inverted', KcLayerType.cutThroughInverted);
        convertToLayerType(page, 'is_crease', KcLayerType.crease);
        convertToLayerType(page, 'is_perforation', KcLayerType.perforation);
        convertToLayerType(page, 'is_kiss_cut', KcLayerType.kissCut);
      }
      const pageLabel = design.labels.pages[index];
      const pageElement = this.createPageElement(page, pageName, pageLabel, index);
      this.enableFoilableByDefault = this.enableFoilableByDefault && pageElement.permissions.isFoilable;
      this.spotUvPermissionForJpgByDefault =
        this.spotUvPermissionForJpgByDefault && pageElement.permissions.isSpotUvable;
      this.canAddSpotUv = this.canAddSpotUv && pageElement.permissions.isSpotUvable;

      // background is always same size as page and has same bleed
      const background = new BackgroundElement({
        width: pageElement.width,
        height: pageElement.height,
        color: page.bg.bgc,
        bleed: pageElement.bleed
      });

      background.permissions.isLocked = !!page.bg.locked;

      // make sure background does not get a conflicting id, but always an unique one
      pageElement.addElement(background, this.generateUniqueId(page, pageElement));

      // get first backgroundImage, ignore others,
      // this means if multiple background images in json other images will disappear from design
      const backgroundImage = page.images.find(element => element.type === KcElementType.photo && element.is_bg_image);

      if (backgroundImage) {
        // make sure id of background matches id in json if background image already in background
        if (backgroundImage.route && backgroundImage.route.length === 3) {
          background.id = backgroundImage.route[1];
        }

        // route of background image is ignored and image is always added to background element
        const bgImage = this.createBackgroundImage(backgroundImage as KcImageElement, background, design.material);
        background.addElement(bgImage, 1);
        this.removeInvisibleElements(bgImage, background);
      } else if (page.bg.sid && page.bg.t !== BackgroundTypes.color) {
        // if no background Image and sid in bg and not type color,
        // create background image from sid (legacy kc pattern backgrounds)
        const backgroundPatternImage = this.createBackgroundImageFromBGPattern(page.bg);
        background.addElement(backgroundPatternImage, 1);
        backgroundPatternImage.permissions.isLocked = true;
        setFillDimensions(background, backgroundPatternImage);
      }

      // a spread can have multiple spreadbackgroundimages, because each single page can have a spreadbackgroundimage
      const spreadBackgroundImages = page.images.filter(
        element => element.type === KcElementType.photo && element.is_bg_image_spread
      );

      if (spreadBackgroundImages.length > 0) {
        spreadBackgroundImages.map(element => {
          const spreadBackgroundImage = this.createBackgroundImageSpread(
            element as KcImageElement,
            pageElement,
            design.material
          );
          const elementId = element.route[0];

          pageElement.addElement(spreadBackgroundImage, elementId);
        });
      }

      // filter out al empty text images and background image(s)
      const ImagesAndNotEmptyText = page.images.filter(
        image =>
          (image.type === KcElementType.photo && !(image.is_bg_image || image.is_bg_image_spread)) ||
          (image.type === KcElementType.text && image.text.trim() !== '') ||
          (image.type === KcElementType.richText && image.text.length > 0 && this.elementHasText(image))
      );

      // TODO: clip_paths should have an order too, especially boxes
      // TODO: photoframes in boxes (nested clip_paths)
      ImagesAndNotEmptyText.map(element => {
        // not all elements in json have a route, so if not create an unique one
        element.route = element.route || [this.generateUniqueId(page, pageElement), pageElement.id];

        const parent = this.getParentElement(element, pageElement, design.material);

        const newElement = createCanvasElement(
          element,
          parent,
          useAbsoluteFontSizeDesign,
          design.material,
          this.foilColors,
          this.addImageAsPhotoFrame,
          this.enableFoilableByDefault,
          this.spotUvPermissionForJpgByDefault,
          this.useAbsoluteFontSizeConfig,
          this.canAddSpotUv
        );
        newElement.parent = parent;

        const elementId = element.route[0];
        parent.addElement(newElement, elementId, element.order);

        this.removeInvisibleElements(newElement, parent);
      });

      // TODO refactor: should use spreads!!
      const id = parseInt(pageName.substr(1), 10);
      design.addPage(pageElement, id);
    });
    design.pages.find(page => !page.permissions.isHidden).visible = true;
    this.addSpreads(design, designView);
    design.updateView(designView);
    design.spreads[0].spreadPage.visible = true;

    // TODO: page id is not the right trigger, doesn't work for spreadview and freeform
    design.setBackSidePageIds();

    // sorting elements on page after toggling spreads! so order of elements will be correct on spread page
    design.pages.forEach(page => page.sortChildren());

    return design;
  }

  getDeprecatedDesignColors(pages: KcDesign, sortedPageNames: string[]): Color[] {
    return uniq(
      sortedPageNames.flatMap((pageName, index) => {
        const page = pages[pageName] as KcPage;
        return page.design_colors || [];
      })
    ).map(c => new Color('', c));
  }

  getSortedPageNames(pages: KcDesign, designData: KcDesignData) {
    const sortedPageNames = Object.keys(pages)
      .filter(page => page[0] === 'p')
      .sort((a, b) => (Number(a.substring(1)) < Number(b.substring(1)) ? -1 : 1));

    if (designData.specs && 'position' in pages[sortedPageNames[0]]) {
      sortedPageNames.sort((a, b) => pages[a].position - pages[b].position);
    }

    return sortedPageNames;
  }

  setDesignLabels(design: Design, designData: KcDesignData, nrOfPages: number) {
    Object.keys(View).map(view => {
      design.labels[view] = this.getDefaultLabels(view, nrOfPages);

      if (!designData.labels || !designData.labels[view]) {
        return;
      }

      const newLabels = cloneDeep(design.labels[view]);

      [...Array(nrOfPages).keys()].map(i => {
        if (designData.labels[view][i]) {
          newLabels[i] = designData.labels[view][i];
        }
      });

      design.labels[view] = newLabels;
    });
  }

  elementHasText(el: KcRichTextElement) {
    return !!el.text.find(par => par.lines.find(line => line.textSpans.find(span => span.text.trim() !== '')));
  }

  generateUniqueId(page: KcPage, pageElement: PageElement) {
    // make sure new id is not in json and not in already added elements
    const elementIdsJson = page.images.filter(el => !!el.route).map(el => el.route[el.route.length - 2]);
    const elementIds = pageElement.children.map(el => el.id);
    return [...elementIdsJson, ...elementIds].reduce((maxId, next) => Math.max(maxId, next), 0) + 1;
  }

  removeInvisibleElements(element: CanvasElement, parent: CanvasElement) {
    const isInvalid = (nr: number) => !nr || nr <= 0;
    if (
      isInvalid(element.height) ||
      isInvalid(element.width) ||
      !this.intersectService.doElementsIntersect(element, parent)
    ) {
      if (parent.isPhotoFrame()) {
        // if parent is photoframe remove parent as well, else you will get an empty photoframe
        this.removeElement(parent.parent, parent.id);
      } else {
        this.removeElement(parent, element.id);
      }
    }
  }

  removeElement(parent: CanvasElement, childId: number) {
    const index = parent.children.findIndex(sibling => sibling.id === childId);
    if (index >= 0) {
      // only remove element if found (splice(-1) removes last element) weird shit will happen!!
      parent.children.splice(index, 1);
    }
  }

  getParentElement(
    element: KcRichTextElement | KcImageElement | KcTextElement,
    pageElement: PageElement,
    material: Material
  ) {
    const isText = element.type === KcElementType.text || element.type === KcElementType.richText;
    const addToPage = !element.clip_path && (!this.addImageAsPhotoFrame || isText);

    if (addToPage) {
      return pageElement;
    }

    let parent: PhotoFrameElement | BoxElement;
    let parentId;

    const parentRoute = element.route.slice(1);

    if (parentRoute.length > 1) {
      parent = pageElement.getElement(parentRoute) as PhotoFrameElement | BoxElement;
    }

    if (!parent) {
      if (element.clip_path) {
        parent = createParentElementFromClipPath(element.clip_path, element.clip_path_type);
        parentId = parentRoute[0];
      } else {
        parent = new PhotoFrameElement(-1, { x: element.x, y: element.y, r: element.r });
        parentId = element.route[0]; // parent route is old kc element route, new child gets id 1;
        element.route = [1, ...element.route];
        setImageDimensions(parent, element as KcImageElement);
      }

      parent.isFoilablePermissionWasChanged =
        element.is_foilable_permission_was_changed !== undefined
          ? element.is_foilable_permission_was_changed
          : parent.isFoilablePermissionWasChanged;

      if (parent.isPhotoFrame()) {
        parent.layerType = getLayerType(parent, element);

        if (element.type === KcElementType.photo && fileExtensionIsJpg(element.sid || element.url))
          parent.isSpotUvForJpgImagePermissionWasChanged =
            element.is_spotuvable_for_jpg_image_permission_was_changed !== undefined
              ? element.is_spotuvable_for_jpg_image_permission_was_changed
              : parent.isSpotUvForJpgImagePermissionWasChanged;
      }
      pageElement.addElement(parent, parentId, element.order);
      // set permissions for parent element
      addElementPermissions(
        parent,
        element,
        material,
        this.enableFoilableByDefault,
        this.spotUvPermissionForJpgByDefault,
        this.canAddSpotUv
      );
      this.removeInvisibleElements(parent, pageElement);
    }
    return parent;
  }

  createBackgroundImage(element: KcImageElement, parent: BackgroundElement, material: Material) {
    const newElement = new ImageElement(-1);
    newElement.setBackgroundImagePermissions();
    newElement.rotation = element.r;
    newElement.sid = (material && material.image ? material.image : false) || element.sid || '';
    newElement.url = element.url || '';
    newElement.color = element.recolor ? element.fg_color : '';

    addCommonProperties(newElement, element);
    setImageDimensions(newElement, element);
    addElementPermissions(
      newElement,
      element,
      material,
      this.enableFoilableByDefault,
      this.spotUvPermissionForJpgByDefault,
      this.canAddSpotUv
    );
    setElementPosition(element, newElement, parent);
    return newElement;
  }

  createBackgroundImageSpread(element: KcImageElement, parent: PageElement, material: Material) {
    const newElement = new BackgroundImageElement(-1);
    newElement.setBackgroundImagePermissions();
    newElement.rotation = element.r;
    newElement.sid = element.sid || '';
    newElement.url = element.url || '';
    newElement.color = element.recolor ? element.fg_color : '';
    newElement.order = element.order;

    addCommonProperties(newElement, element);
    setImageDimensions(newElement, element);
    addElementPermissions(
      newElement,
      element,
      material,
      this.enableFoilableByDefault,
      this.spotUvPermissionForJpgByDefault,
      this.canAddSpotUv
    );
    setElementPosition(element, newElement, parent);
    return newElement;
  }

  createPageElement(page: KcPage, name: string, pageLabel: string[], index: number) {
    const pageElement = new PageElement(index + 1);
    pageElement.order = index;
    pageElement.name = name;
    pageElement.width = page.w;
    pageElement.height = page.h;
    pageElement.label = pageLabel;
    pageElement.bleed = page.bleed !== undefined ? page.bleed : pageElement.bleed;
    pageElement.safety = page.safety !== undefined ? page.safety : pageElement.safety;
    pageElement.permissions.addChildElements = page.add_child_elements !== undefined ? page.add_child_elements : true;
    pageElement.permissions.isLocked = !!page.locked;
    pageElement.permissions.isHidden = !!page.is_hidden;
    pageElement.position = page.position;
    pageElement.permissions.isFoilable = page.foil_enabled ?? true;
    pageElement.permissions.isSpotUvable = page.spot_uv_enabled ?? true;
    return pageElement;
  }

  createBackgroundImageFromBGPattern(bg: KcBackground) {
    return new ImageElement(-1, {
      sid: bg.sid,
      color: bg.fgc,
      width: 500,
      height: 500,
      y: -10,
      x: -10
    });
  }

  addSpreads(design: Design, view: View) {
    const spreads = getSpreads(design, view);
    spreads.forEach(spread => design.addSpread(spread));
  }

  getDefaultLabels(view: string, nrOfPages: number) {
    const defaultLabels = this.getTextService.text.design.defaultLabels[view];
    let nrOfSpreadPages: number;

    // for triptych return default for userSpreads for both spreadview and cardview
    if (nrOfPages === 6 && view !== View.pages) {
      return this.getTextService.text.design.defaultLabels.userSpreads[nrOfPages];
    }

    switch (view) {
      case View.pages: {
        return [...Array(nrOfPages).keys()].map(index =>
          defaultLabels[nrOfPages] ? defaultLabels[nrOfPages][index] : ['Page ' + (index + 1)]
        );
      }

      case View.userSpreads: {
        // if nrOfPages is odd (i.e. in case of democanvas), don't set any userspreadlabels
        if (nrOfPages % 2 !== 0) {
          return [];
        }

        nrOfSpreadPages = nrOfPages / 2;
        return defaultLabels[nrOfPages] || [...Array(nrOfSpreadPages).keys()].map(index => ['Spread ' + (index + 1)]);
      }

      case View.card: {
        // if nrOfPages is odd (i.e. in case of democanvas), don't set any cardlabels
        if (nrOfPages % 2 !== 0) {
          return [];
        }

        nrOfSpreadPages = (nrOfPages - 2) / 2;
        const labelFirstPage = defaultLabels[0];
        const labelLastPage = defaultLabels[defaultLabels.length - 1];
        const cardLabels = [...Array(nrOfSpreadPages).keys()].map(index => [
          defaultLabels[1] + (nrOfSpreadPages > 1 ? ' ' + (index + 1) : '')
        ]);

        cardLabels.unshift(labelFirstPage);
        cardLabels.push(labelLastPage);
        return cardLabels;
      }
    }
  }
}
