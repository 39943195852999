export enum FabricCanvasActionTypes {
  ChangeInlineTextFontFamily = '[Fabric][Canvas][InlineText] Change font family',
  ChangeInlineTextFontSize = '[Fabric][Canvas][InlineText] Change font size',
  ChangeInlineTextFontStyle = '[Fabric][Canvas][InlineText] Change font style',
  ChangeInlineTextFontWeight = '[Fabric][Canvas][InlineText] Change font weight',
  ChangeInlineTextLineHeight = '[Fabric][Canvas][InlineText] Change line height',
  ChangeInlineTextTextAlign = '[Fabric][Canvas][InlineText] Change text align',
  ChangeInlineTextUnderline = '[Fabric][Canvas][InlineText] Change underline',
  ChangeInlineTextOpacity = '[Fabric][Canvas][InlineText] Change opacity',
  ChangePhotoFrameZoom = '[Fabric][Canvas][PhotoFrame] Change zoom',
};
